import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'the-platform';


const CurrentPog = ({ t }) => {
    const dispatch = useDispatch();
    const smallDevice = useMedia('(max-width: 959px)');
    let currentPog = useSelector(state => state.appReducer.currentPog);

    const setCurrentPogCheckFun = () => {
        dispatch({ type: 'SET_CURRENT_POG', currentPog: !currentPog })
        if (!currentPog) {
            dispatch({ type: 'SET_CURRENT_RANK', currentRank: false })
        }
    }

    const memorizedCurrentPog = useMemo(() =>
        <>
            <input type="checkbox" id="todo" name="todo" checked={currentPog} onChange={setCurrentPogCheckFun} />
            <label className="chk-label" htmlFor="todo" data-content={`${smallDevice ? 'POG' : 'Rank by POG'}`}>{`${smallDevice ? t('POG') : t('Rank by POG')}`}</label>
        </>, [currentPog, setCurrentPogCheckFun, t]);

    return <div className={"vista-search-current-rank w-100 background-theme-blue"}>
        {memorizedCurrentPog}
    </div>
}

export default   withTranslation()(CurrentPog);