import React, { Fragment, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import actions from '../../redux/actions';
import { Box, Menu, Button, List, ListItem , Select, MenuItem} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import resources from '../../utils/locales.json';
const supportedLanguagues = {
    "en": "English",
    "tr-TR": "Turkish",
    "es-ES" : "Spanish"
}

export default function ChangeLanguage({fromAppBar = false}) {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [language, setLanguage] = React.useState(i18n.language);
    const dispatch = useDispatch()
    const languages = ["en", ...Object.keys(resources)];
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        i18n.changeLanguage(language)
        dispatch({ type: actions.SET_CURRENT_LOCALE, locale: language });
    }, [language, i18n])


    if (fromAppBar) {
        return  <Select variant='outlined' className="search-select w-100 app-bar-select-menus" value={i18n.language==="en-US"? "en": i18n.language} onChange={(e) => setLanguage(e.target.value)} >
            {languages.map((v, i) => <MenuItem key={i} value={v}> {supportedLanguagues[v] || v}</MenuItem>)}
        </Select>
    } else {
      return  <Fragment>                        
        <Button onClick={handleClick} className="text-capitalize px-3 btn-inverse d-flex ">
            <Box>
                <span className="lang-text">{supportedLanguagues[i18n.language] || i18n.language }</span>
            </Box>
            <span className="pl-1 pl-xl-3">
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </span>
        </Button>
        <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            onClose={handleClose} >
            <div className=" overflow-hidden p-0">
                <List className="bg-transparent d-flex  flex-column ">
                    {languages.map((v, i) => {
                        return <ListItem key={ i} className="d-block rounded-bottom  text-center">
                            <span style={{cursor:"pointer"}}  className="btn btn-block " onClick={()=> setLanguage(v) }> {supportedLanguagues[v] || v}</span>
                        </ListItem>
                    })}
                </List>
            </div>
        </Menu>
    </Fragment >
    }
}
