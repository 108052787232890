import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import MuiTheme from '../theme';
import LeftSidebar from '../layout/LeftSidebar';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import utils from '../utils';
import useGaTracker from '../gaTracker'
import Loader from '../components/Dialog/Loader';
import VistaImagePopup from '../components/VistaLocationCardList/VistaImagePopup';
import { HelmetProvider } from 'react-helmet-async';
import * as locales from "@material-ui/core/locale";
import useFreshChat from '../utils/freshChat'
import actions from '../redux/actions';
import { useTranslation } from 'react-i18next';

const t = utils.t

function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

// Old Routes imports TODO confirm with team and remove
// const SurveyDashboard = lazy(() => retry(() => import('../components/SurveyDashboardComponent/surveyDashboard')));
// const Notifications = lazy(() => retry(() => import('../pages/Notifications')));
// const VistaLocationDetails = lazy(() => retry(() => import('../pages/VistaLocationDetails')));
// const VistaCameras = lazy(() => retry(() => import('../pages/VistaCameras')));
// const Locations = lazy(() => retry(() => import('../pages/Locations')));
// const ScheduleOrderHistory = lazy(() => retry(() => import('../pages/ScheduleOrderHistory')));
// const PresalesReport = lazy(() => retry(() => import('../pages/AreaReport/PresalesReport')));
// const OutOfStock = lazy(() => retry(() => import('../pages/OutOfStock')));
// const Deliveries = lazy(() => retry(() => import('../pages/Deliveries')));
// const MaintenanceTasks = lazy(() => retry(() => import('../pages/MaintenanceTasks')));
// const MaintenanceReport = lazy(() => retry(() => import('../pages/MaintenanceReport')));
// const OutOfStockReport = lazy(() => retry(() => import('../pages/OutOfStockReport')));
// const ExecutiveReport = lazy(() => retry(() => import('../pages/ExecutiveReport')));
// const RouteManagerReport = lazy(() => retry(() => import('../pages/RouteManagerReport')));
// const TelemetricDashboard = lazy(() => retry(() => import('../pages/TelemetricDashboard')));
// const LocationReorder = lazy(() => retry(() => import('../pages/LocationReorder')));
// const InTransitList = lazy(() => retry(() => import('../pages/LocationReorder/in-Transit')));
// const ScheduledOrderLogs = lazy(() => retry(() => import('../pages/Logs/ScheduledOrder')));
// const OrderHistoryLogs = lazy(() => retry(() => import('../pages/Logs/OrderHistoryLogs')));
// const KPIDashboard = lazy(() => retry(() => import('../pages/KPIDashboard')));
// const OutletReport = lazy(() => retry(() => import('../pages/UnileverReport/outletReport')));;
// const InFieldHardware = lazy(() => retry(() => import('../pages/UnileverReport/inFieldHardware')));
// const AreaReport = lazy(() => retry(() => import('../pages/AreaReport/index')));
// const SurveyReport = lazy(() => retry(() => import('../pages/SurveyReport')));

const Login = lazy(() => retry(() => import('../pages/Login')));
const VistaLocationDetails2 = lazy(() => retry(() => import('../pages/VistaLocationDetails2')));
const Home = lazy(() => retry(() => import('../pages/Home/index')));
const AcostaReport = lazy(() => retry(() => import('../pages/AcostaReport')));
const AssetReport = lazy(() => retry(() => import('../pages/assetReport')));
const BusinessKPIHome = lazy(() => retry(() => import('../pages/BusinessPerformance')));
const BusinessKPI = lazy(() => retry(() => import('../pages/BusinessPerformance/businessKPI')));
const DataAndSettingHome = lazy(() => retry(() => import('../pages/DataAndSetting')));
const DataAndSetting = lazy(() => retry(() => import('../pages/DataAndSetting/dataAndSetting')));
const OperationsHome = lazy(() => retry(() => import('../pages/Operations/')));
const Operations = lazy(() => retry(() => import('../pages/Operations/operations')));
const ReplenishmentHome = lazy(() => retry(() => import('../pages/Replenishment')));
const OutletCards = lazy(() => retry(() => import('../pages/Outlets')));
const ChooseSSO = lazy(() => retry(() => import('../pages/Login/ChooseSSO')));
const Replenishment = lazy(() => retry(() => import('../pages/Replenishment/Replenishment')));
const SurveyHome = lazy(() => retry(() => import('../pages/Survey')));
const Survey = lazy(() => retry(() => import('../pages/Survey/survey')));
const SurveyAnswer = lazy(() => retry(() => import('../pages/RenderSurveyAnswer')));
const Tutorials = lazy(() => retry(() => import('../pages/Tutorials')));
const TrueGatewaygHome = lazy(() => retry(() => import('../pages/TrueGateway')));
const TrueDashboard = lazy(() => retry(() => import('../pages/TrueGateway/trueGateway')));

let loginDataCount = 0;

const SuspenseLoading = ({t, tOpts}) => <>{t("Loading...", tOpts)}</>

const processReLogin = (loginData, history, dispatch) => {
    if (Object.keys(loginData).length === 0 && !loginDataCount) {
        utils.reLogin(history); //re-login on redirect
        loginDataCount++;
        return false;
    }
}
const loggedIn = (loginData) => {
    let message = loginData && loginData.message ? loginData.message : "";
    return (message === "Logged in");
};

const MasterPageAuthenticatedRoute = React.memo(({ component: DynamicComponent, ...rest }) => {
    const loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {},
        dispatch = useDispatch(),
        history = useHistory();
    dispatch({
        type: actions.SET_FILTER_VALUES, filterValues: {}
    });
    return <Route {...rest} render={props => {
        processReLogin(loginData, history, dispatch);
        if (loggedIn(loginData)) {
            // Check if current route is the one with params (a child route)
            if (props?.match?.params && Object.keys(props.match.params).length) {
                let backRoute = props.match.path;
                // Extract the parent route by removing the param and extra slash
                backRoute = backRoute.split(':')[0].slice(0, -1);
                // Apply back button to directly go to parent
                dispatch({ type: actions.SET_PAGE_BACK_BUTTON, pageBackButton: { status: true, backRoute: backRoute } });
            } else {
                // disable back button in case of parent route 
                dispatch({ type: actions.SET_PAGE_BACK_BUTTON, pageBackButton: { status: false, backRoute: '' } });
            }
            return <DynamicComponent {...props} />
        } else {
            if (Object.keys(loginData).length === 0 && !loginDataCount) {
                utils.reLogin(history); //re-login on redirect
                return false;
            }
            else if (loginDataCount > 1) {
                return <Redirect to="/" />
            }
        }
    }} />
});

const Routes = () => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    let loginData = useSelector(state => state.appReducer.userData, shallowEqual) || {};
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const routePath = Object.keys(loginData).length ? utils.getAssignedRoutes(loginData) : []
    const routeDirection = [];
    routeDirection.push("");
    React.useEffect(() => {
        const isLoginDataExist = Boolean(Object.keys(loginData)?.length !== 0);
        if (!location.pathname.includes("/assetReport/") && !isLoginDataExist)
            processReLogin(loginData, history, dispatch);
    }, [loginDataCount, loginData]);

    for (let path of routePath) {
        if (loginData && loginData.modules && loginData.modules[path.slice(1)]?.Module) {
            routeDirection.push(path)
        }
    }

    const pageVariants = {
        initial: { opacity: 0 },
        in: { opacity: 1 },
        out: { opacity: 0 }
    };

    const pageTransition = { type: 'tween', ease: 'anticipate', duration: 0.4 };
    let currentLocale = useSelector(state => state.appReducer.locale) || "";
    currentLocale = currentLocale.replace("-", "");
    useGaTracker(); // Initialize the GA Tracker
    useFreshChat({ loginData }); // Initialize the Fresh chat

    return (
        <HelmetProvider>
            <ThemeProvider theme={createTheme(MuiTheme, locales[currentLocale])}>
                <AnimatePresence>
                    <Suspense fallback={<SuspenseLoading t={t} tOpts={tOpts} />}>
                        <Switch>
                            <MasterPageAuthenticatedRoute path="/" exact component={Login} />
                            <Route path={['/login']}>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/login" component={Login} />
                                    </motion.div>
                                </Switch>
                            </Route>
                            <Route path={['/choosesso']}>
                                <Switch location={location} key={location.pathname}>
                                    <motion.div
                                        initial="initial"
                                        animate="in"
                                        exit="out"
                                        variants={pageVariants}
                                        transition={pageTransition}>
                                        <Route path="/choosesso" component={ChooseSSO} />
                                    </motion.div>
                                </Switch>
                            </Route>
                            <Route exact path="/assetReport/:GUID" component={AssetReport} />
                            <Route path={routeDirection}>
                                <LeftSidebar>
                                    <Suspense fallback={<SuspenseLoading t={t} tOpts={tOpts} />}>
                                        <Switch location={location} key={location.pathname}>
                                            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition} style={{ height: "100%", display: 'flex', flexDirection: 'column' }}>
                                                {/* Old Routes TODO confirm with team and remove*/}
                                                {/* <MasterPageAuthenticatedRoute path="/Notifications" component={Notifications} />
                                                <MasterPageAuthenticatedRoute path="/VistaLocationDetails" component={VistaLocationDetails} />
                                                <MasterPageAuthenticatedRoute path="/VistaCameras" component={VistaCameras} />
                                                <MasterPageAuthenticatedRoute path="/PresalesReport" component={PresalesReport} />
                                                <MasterPageAuthenticatedRoute path="/OutOfStock" component={OutOfStock} />
                                                <MasterPageAuthenticatedRoute path="/Locations" component={Locations} />
                                                <MasterPageAuthenticatedRoute exact path="/SurveyReport" component={SurveyDashboard} />
                                                <MasterPageAuthenticatedRoute path="/ScheduledOrderStatusHistory" component={ScheduleOrderHistory} />
                                                <MasterPageAuthenticatedRoute path="/Deliveries" component={Deliveries} />
                                                <MasterPageAuthenticatedRoute path="/MaintenanceTasks" component={MaintenanceTasks} />
                                                <MasterPageAuthenticatedRoute path="/MaintenanceReport" component={MaintenanceReport} />
                                                <MasterPageAuthenticatedRoute path="/OutOfStockReport" component={OutOfStockReport} />
                                                <MasterPageAuthenticatedRoute path="/ExecutiveReport" component={ExecutiveReport} />
                                                <MasterPageAuthenticatedRoute path="/RouteManagerReport" component={RouteManagerReport} />
                                                <MasterPageAuthenticatedRoute path="/TelemetricDashboard" component={TelemetricDashboard} />
                                                <MasterPageAuthenticatedRoute path="/LocationReorder" component={LocationReorder} />
                                                <MasterPageAuthenticatedRoute path="/In-Transit" component={InTransitList} />
                                                <MasterPageAuthenticatedRoute path="/ScheduledOrderLogs" component={ScheduledOrderLogs} />
                                                <MasterPageAuthenticatedRoute path="/OrderHistory" component={OrderHistoryLogs} />
                                                <MasterPageAuthenticatedRoute path="/KPI-Dashboard" component={KPIDashboard} />
                                                <MasterPageAuthenticatedRoute path="/OutletReport" component={OutletReport} />
                                                <MasterPageAuthenticatedRoute path="/SurveyDashboard" component={SurveyReport} />
                                                <MasterPageAuthenticatedRoute  path="/AreaReport" component={AreaReport} />
                                                <MasterPageAuthenticatedRoute path="/InFieldHardware" component={InFieldHardware} /> */}

                                                {/* New Routes */}
                                                <MasterPageAuthenticatedRoute exact path="/Home" component={Home} />
                                                <MasterPageAuthenticatedRoute exact path="/DataAndSetting" component={DataAndSettingHome} />
                                                <MasterPageAuthenticatedRoute exact path="/DataAndSetting/:page" component={DataAndSetting} />
                                                <MasterPageAuthenticatedRoute exact path="/DataAndSetting/:page/:subPage" component={DataAndSetting} />
                                                <MasterPageAuthenticatedRoute exact path="/DataAndSetting/:page/:subPage/:id" component={DataAndSetting} />
                                                <MasterPageAuthenticatedRoute exact path="/Operations" component={OperationsHome} />
                                                <MasterPageAuthenticatedRoute exact path="/Operations/:page" component={Operations} />
                                                <MasterPageAuthenticatedRoute exact path="/VistaLocation" component={VistaLocationDetails2} />
                                                <MasterPageAuthenticatedRoute exact path="/Outlet" component={OutletCards} />
                                                <MasterPageAuthenticatedRoute exact path="/BusinessPerformance/" component={BusinessKPIHome} />
                                                <MasterPageAuthenticatedRoute exact path="/BusinessPerformance/:page" component={BusinessKPI} />
                                                <MasterPageAuthenticatedRoute exact path="/Replenishment" component={ReplenishmentHome} />
                                                <MasterPageAuthenticatedRoute exact path="/Replenishment/:page" component={Replenishment} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey" component={SurveyHome} />
                                                <MasterPageAuthenticatedRoute exact path="/Survey/:page" component={Survey} />
                                                <MasterPageAuthenticatedRoute exact path="/SurveyReport/:LocationId" component={SurveyAnswer} />
                                                <MasterPageAuthenticatedRoute exact path="/AcostaReports" component={AcostaReport} />
                                                <MasterPageAuthenticatedRoute exact path="/Tutorials" component={Tutorials} />
                                                <MasterPageAuthenticatedRoute exact path="/TrueGateway" component={TrueGatewaygHome} />
                                                <MasterPageAuthenticatedRoute exact path="/TrueGateway/:page" component={TrueDashboard} />
                                            </motion.div>
                                        </Switch>
                                    </Suspense>
                                </LeftSidebar>
                                <Loader />
                                <VistaImagePopup />
                            </Route>
                        </Switch>
                    </Suspense>
                </AnimatePresence>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default Routes;