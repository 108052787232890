import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../ImageViewer/Modal'

const VistaImagePopup = ({ isMobile }) => {
    const dispatch = useDispatch();
    let { url = '', imageName = '', open = false, isMultiImages = false, title = "", timeStamp = "", useSingleDisplay = false, showIndex = 0 } = useSelector(state => state.appReducer.vistaPopupData);

    const closeModal = () => {
        dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: false, url: '' } })
    }
    return <div className="image-gallery">
        <Modal
            images={!isMultiImages ? [{ src: url, title: imageName, content: '', imageTitle: title, timeStamp: timeStamp }] : url}
            showIndex={showIndex}
            showPreview
            open={open}
            closeModal={closeModal}
            isMultiImages={isMultiImages}
            useSingleDisplay={useSingleDisplay}
        />
    </div>
}

export default VistaImagePopup;