import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import MuiTypography from '../MUITypography';
import useMobile from '../../utils/useMobile';

function PageTitle(props) {
    const {
        pageTitleStyle,
        mediaQuery,
        pageTitleBackground,
        titleHeading,
        name = null,
        icon: Icon,
        iconclass,
        RightComponent = null,
        mobileRightComponent,
        title = "",
        titleDescription = "",
        titleClass = 'text-theme-blue text-max-width',
        t,
        showTitleInfo } = props;
    const isMobile = useMobile(true);

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <MuiTypography className="print-only" text={titleHeading} />
            <div className={!showTitleInfo ? clsx(pageTitleStyle, pageTitleBackground) : ""}>
                {/* Icon render */}
                {Icon &&
                    <Box>
                        <Icon iconclass={iconclass || "cameraIconTitle"} className={iconclass || "cameraIconTitle"} />
                    </Box>}
                {/* Title render */}
                <Box className="app-page-title--first">
                    {mediaQuery ?
                        <div className={`app-page-title--heading-media ${isMobile ? 'small-text pl-2' : ''}`}>
                            <h1 className={`${titleClass}  ${isMobile ? 'display-4 pl-2' : ''} `}>{t(titleHeading)}</h1>
                        </div> :
                        <div className={`${!showTitleInfo ? "app-page-title--heading" : ""} ${titleDescription ? "mt-2" : ''}`}>
                            {showTitleInfo ? showTitleInfo : <MuiTypography className={`${titleClass} page-text-title`} variant='p' text={titleHeading} name={name} />}
                            {titleDescription && <MuiTypography className={`${titleClass} page-text-description`} variant='p' component='p' text={titleDescription} />}
                        </div>}
                </Box>
                {/* For Mobile */}
                {!isMobile &&
                    <>
                        <Box> {RightComponent && <RightComponent />} </Box>
                        <Box> {mobileRightComponent} </Box>
                    </>}
            </div>
        </>
    );
}

export default withTranslation()(PageTitle);
