
import React from "react";
import ButtonWithIcon from "./menuComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router";

function Print({ t: trans, i18n, history, label }) {
    let { onPrint } = useSelector(state => state.appReducer.filtersInHeader) || {};

    if (!onPrint) {
        onPrint = () => {
            console.log(`printing`)
            window.print();
        }
    }

    return <>
        <ButtonWithIcon size="small" handleClick={onPrint} disabled={!onPrint} btnClass={"background-theme-blue sub-header-button"} showIconOnMobile={true} showLabelOnWeb={true} icon={<FontAwesomeIcon icon={faPrint} />} showOnMobile={true} label={label} menuItems={[]} iconButtonClass="iconButtonClass" />
    </>
}

export default withTranslation()(withRouter(Print));