
const env = process.env;
let url = (window.location.host.indexOf("localhost") !== -1) ? '' : env.REACT_APP_HOST;
let urlWithControllers = url + "/Controllers/"
const apis = {
    url: url,
    imgBaseUrl: env.REACT_APP_HOST,
    login: urlWithControllers + 'Login.ashx',
    assetLatestImages: urlWithControllers + 'AssetLatestImages.ashx',
    assetLatestImagesNew: urlWithControllers + 'AssetLatestImages2.ashx',
    smartDevice: urlWithControllers + 'SmartDevice.ashx',
    Asset: urlWithControllers + 'Asset.ashx',
    AssetPurityList: urlWithControllers + 'AssetPurity.ashx',
    OutOfStockReport: urlWithControllers + 'OutOfStockReport.ashx',
    AreaReports: urlWithControllers + 'AreaReports.ashx',
    Combo: urlWithControllers + 'Combo.ashx',
    productImageURL: url + '/products/',
    SkuOutOfStock: urlWithControllers + 'SkuOutOfStock.ashx',
    Telemetric: urlWithControllers + 'Telemetric.ashx',
    imageBaseDirectUrl: 'https://portal.coolrgroup.com/Controllers/CoolerImagePreview-Stitched-',
    UnileverReorder: urlWithControllers + 'UnileverReorder.ashx',
    ScheduledOrder: urlWithControllers + 'ScheduledOrder.ashx',
    AutoStock: urlWithControllers + 'AutoStock.ashx',
    ReorderDetail: urlWithControllers + 'ReorderDetail.ashx',
    ClientLogoBaseUrl: urlWithControllers + 'CoolerImagePreview.ashx',
    ScheduledOrderExport: `${urlWithControllers}ScheduledOrderExport.ashx`,
    assetLatestImages2: `${urlWithControllers}AssetLatestImages2.ashx`,
    ScheduledOrderMailHistory: `${urlWithControllers}ScheduledOrderMailHistory.ashx`,
    AcostaValidateReport: `${urlWithControllers}AcostaValidateReport.ashx`,
    LocationOrderHistory: `${urlWithControllers}LocationOrderHistory.ashx`,
    ImageProcessing: `${urlWithControllers}ImageProcessing.ashx`,
    DashboardHomeCharts: `${urlWithControllers}DashboardHomeCharts.ashx`,
    KPIReport: `${urlWithControllers}KPIReport.ashx`,
    KPIConfig: `${urlWithControllers}KPIReport.ashx?action=otherAction&otherAction=GetClientKPIConfig`,
    Preference: `${urlWithControllers}GridPreferenceManager.ashx`,
    SwitchClient: `${urlWithControllers}SwitchClient.ashx`,
    Survey: urlWithControllers + 'Survey.ashx',
    ScheduledOrderStatusHistory: urlWithControllers + 'ScheduledOrderStatusHistory.ashx',
    SurveyType: urlWithControllers + "SurveyType.ashx",
    SurveyDetails: urlWithControllers + "SurveyDetails.ashx",
    Thumbnail: urlWithControllers + "Thumbnail.ashx",
    ClientUser: urlWithControllers + "ClientUser.ashx",
    Location: urlWithControllers + "Location.ashx",
    SystemUpdate: urlWithControllers + "Client.ashx",

    // Reporting Tool API's
    KPIData: `${url}/internal-reporting/kpi`,
    KPIDetail: `${url}/internal-reporting/kpi/detail`,
    KPISummary: `${url}/internal-reporting/kpi/summary`,
    KPIFilter: `${url}/internal-reporting/kpi/lookupRelations`,
    MasterDataDash: `${url}/internal-reporting/dashboard/masterData`,
    ProductAvailabilityDash: `${url}/internal-reporting/dashboard/productAvailability`,
    InFieldHardware: `${url}/internal-reporting/dashboard/inFieldHardware`,
    AlertHistory: `${url}/internal-reporting/dashboard/operationsAlert`,
    SurveyDashboard: `${url}/internal-reporting/dashboard/surveyData`,
    ProductDistribution: `${url}/internal-reporting/dashboard/productDistribution`,
    PlanogramAsset: `${url}/internal-reporting/dashboard/planogramAsset`,
    OutletCardView: `${url}/internal-reporting/dashboard/outletView`,
    CreateOutletGroupList: `${url}/internal-reporting/auditoutlet/createAndUpdate`,
    GetOutletGroupList: `${url}/internal-reporting/auditoutlet/list`,
    DeleteOutletGroupList: `${url}/internal-reporting/auditoutlet/delete`,
    DeleteOutlet: `${url}/internal-reporting/auditoutlet/deleteoutlet`,
    CreateUserGroupList: `${url}/internal-reporting/audituser/createAndUpdate`,
    GetUserGroupList: `${url}/internal-reporting/audituser/list`,
    DeleteUserGroupList: `${url}/internal-reporting/audituser/delete`,
    GetUserGroupMemberList: `${url}/internal-reporting/audituser/listUserGroupMember`,
    GetInGroupsData: `${url}/internal-reporting/audituser/listInGroups`,
    DeleteUser: `${url}/internal-reporting/audituser/delete/members`,
    LoadCombo: `${url}/internal-reporting/combos/loadCombo`,
    TrueDashboard: `${url}/internal-reporting/truedashboard/sensorReading`,
    DeleteOutletGroupListMember: `${url}/internal-reporting/auditoutlet/delete/members`,

    //External API
    TurkeyOrderValidate: "https://siparisapi.perabayi.net/telemetri/coolr/calculate"
}

export default apis;