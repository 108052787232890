import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { request, apis } from "../httpUtil";
import actions from '../redux/actions'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import localization from '../components/Common/Localization';
import { store } from '../redux/store'
import swal from 'sweetalert';
import { MOVEMENT_STATUS, ORDER_STATUS } from '../constants';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from "@material-ui/core/Button";
import toast from 'react-hot-toast';
import { getGridDateOperators } from '@mui/x-data-grid-pro';
import 'dayjs/locale/tr';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
const toastConfig = {
	position: 'bottom-right',
	style: {
		background: '#000',
		color: '#fff'
	}
}

dayjs.extend(localizedFormat);

const comboStores = ["SalesPerson", "LocationClassification", "Market", "LocationType", "State", "RestockDays", "OrderRange", "CaseRange", "FilterType", "AlertStatus", "ReorderLocationStatus", "Client", "AlertType", "Product"];
const reportingToolCombos = ["Users", "OutletGroup", "UserGroup", "SurveyType", "PostalCode", "City"];
let loading = false;
const utils = {
	vistaGetDataFun: null,
	dashboardMenuId: 7184,
	batteryPermissionClientIds: [134],
	unileverUKClient: [291],
	SEARCH_DEBOUNCE: 800,
	dateFormat: "YYYY-MM-DD",
	dateTimeFormat: 'YYYY-MM-DD hh:mm:ss A',
	timeFormat: 'hh:mm A',
	Google_ApiKey: 'AIzaSyDsD1XV1hcrT05Rs896wUQ7wC7Dm50Pc3A',
	MUI_License: 'cc97b7d6858f3e0a42873e332a75e44cT1JERVI6NDE5MDIsRVhQSVJZPTE2ODE1NzcyNzAwMDAsS0VZVkVSU0lPTj0x',
	autoSizerEnum: {
		heightOffset: {
			smallDevice: 10,
			mediumDevice: 24,
			default: 0
		},
		rowHeight: {
			smallDevice: 538,
			mediumDevice: 100,
			default: 0
		}
	},
	gridFilterOperatorMappings: {
		"contains": {
			operator: "like",
			value: "%@value%"
		},
		"startsWith": {
			operator: "like",
			value: "@value%"
		},
		"endsWith": {
			operator: "like",
			value: "%@value"
		},
		"equals": {
			operator: "="
		},
		"isAnyOf": {
			operator: "IN",
		},
		"isNotEmpty": {
			operator: "IS NOT",
		},
		"isEmpty": {
			operator: "IS",
		},
		is: {
			operator: "="
		},
		not: {
			operator: "!="
		},
		after: {
			operator: ">"
		},
		before: {
			operator: "<"
		},
		"onOrBefore": {
			operator: "<="
		},
		"onOrAfter": {
			operator: ">="
		},

	},
	filterType: {
		"contains": "STRING_CONTAINS",
		"startsWith": "STRING_STARTS_WITH",
		"endsWith": "STRING_ENDS_WITH",
		"equals": "STRING_EQUAL",
		"isAnyOf": "STRING_LIST",
		"isNotEmpty": "STRING_DIFFERENT",
		"isEmpty": "STRING_EQUAL",
		"is": "DATE_EQUAL",
		"onOrBefore": "DATE_LESS_OR_EQUAL",
		"onOrAfter": "DATE_GREATER_OR_EQUAL",
		"not": "DATE_NOT_EQUAL",
		"before": "DATE_LESS",
		"after": "DATE_GREATER",
		// "isEmpty": "DATE_ISNULL",
		// "isNotEmpty": "DATE_IS_NOT_NULL",
		"date_range": "DATE_RANGE",
		"=": "NUMBER_EQUAL",
		"!=": "NUMBER_NOT_EQUAL",
		">": "NUMBER_GREATER",
		">=": "NUMBER_GREATER_OR_EQUAL",
		"<": "NUMBER_LESS",
		"<=": "NUMBER_LESS_OR_EQUAL"
	},
	kpiTables: {
		'assetPurity': ['KPI6', 'KPI7', 'KPI8', 'KPI9', 'KPI10', 'PurityPercentage', "CaseSuggested", "ValueSuggested"],
		'assetDayKPI': ['SalesOpportunity', 'TargetPortfolioCompliance', 'TargetPortfolioOutOfStock', 'AssortmentDepth', 'FacingCompliance', 'DoorOpenDailyCount']
	},
	kpiUnits: {
		'AssetsCovered': '',
		'AssortmentDepth': '',
		'DoorOpenDailyCount': '',
		'KPI6': "%",
		'TargetPortfolioCompliance': "%",
		'TargetPortfolioOutOfStock': "%",
		'KPI9': "",
		'KPI10': "%",
		"PurityPercentage": "%",
		'SalesOpportunity': "",
		"FacingCompliance": "%",
		"PlanogramCompliance": "%",
		"CaseSuggested": "",
		"ValueSuggested": "",
		"KPI1": "",
		"KPI2": "",
		"KPI3": "",
		"KPI4": "",
		"KPI5": "",
		"TotalWeight": '',
		'ForeignProductPercentage': "%",
		'EmptyProductPercentage': "%"
	},
	kpiNames: {
		salesOpportunity: "SalesOpportunity",
		valueSuggested: "ValueSuggested",
		planogramCompliance: "PlanogramCompliance",
		assetsCovered: "AssetsCovered",
		doorOpenCount: "DoorOpenDailyCount"
	},
	customDateOperators() {
		let newOperators = getGridDateOperators(true).filter(operator => operator.value !== 'not');
		const operatorsToRemove = ["not", "isEmpty", "isNotEmpty", "is"];
		for (const op of operatorsToRemove) {
			newOperators = newOperators.filter(operator => operator.value !== op)
		}
		return newOperators;
	},
	DEFAULT_KPI_GROUP_BY: [
		{ label: "Market", dataKey: "MarketId", value: true },
		{ label: "Classification", dataKey: "ClassificationId", value: false, },
		{ label: "Channel", dataKey: "LocationTypeId", value: false, },
		{ label: "Asset Type", dataKey: "Asset.AssetTypeId", value: false, },
	],
	dateOperator: {
		"onOrBefore": "<=",
		"onOrAfter": ">=",
		"not": "!=",
		"before": "<",
		"after": ">",
		"is": "="
	},
	operatorSign: {
		"DATE_EQUAL": "=",
		"DATE_LESS_OR_EQUAL": "<=",
		"DATE_GREATER_OR_EQUAL": ">=",
		"DATE_NOT_EQUAL": "!=",
		"DATE_LESS": "<",
		"DATE_GREATER": ">"
	},
	chartColors: [
		{ bg: 'rgba(255, 99, 132, 0.7)', br: 'rgba(255, 99, 132)' },		// red
		{ bg: 'rgba(54, 162, 235, 0.7)', br: 'rgba(54, 162, 235)' },		// blue
		{ bg: 'rgba(75, 192, 192, 0.7)', br: 'rgba(75, 192, 192)' },		// green
		{ bg: 'rgba(255, 206, 86, 0.7)', br: 'rgba(255, 206, 86)' },		// yellow
		{ bg: 'rgba(255, 159, 64, 0.7)', br: 'rgba(255, 159, 64)' },		// orange
		{ bg: 'rgba(153, 102, 255, 0.7)', br: 'rgba(153, 102, 255)' },	// purple
		{ bg: 'rgba(201, 203, 207, 0.7)', br: 'rgba(201, 203, 207)' },	// grey
		{ bg: 'rgba(255, 55, 132, 0.7)', br: 'rgba(255, 55, 132)' },
		{ bg: 'rgba(247, 120, 37, 0.7)', br: 'rgba(247, 120, 37)' },
		{ bg: 'rgba(0, 168, 19, 0.7)', br: 'rgba(0, 168, 19)' },
		{ bg: 'rgba(55, 159, 122, 0.7)', br: 'rgba(55, 159, 122)' },
		{ bg: 'rgba(204, 39, 56, 0.7)', br: 'rgba(204, 39, 56)' },
		{ bg: 'rgba(139, 98, 138, 0.7)', br: 'rgba(139, 98, 138)' },
		{ bg: 'rgba(143, 190, 0, 0.7)', br: 'rgba(2143, 190, 0)' },
		{ bg: 'rgba(96, 96, 96, 0.7)', br: 'rgba(96, 96, 96)' },
	],
	chartColorsD: [
		'rgba(255, 99, 132)',		// red
		'rgba(54, 162, 235)',		// blue
		'rgba(75, 192, 192)',		// green
		'rgba(255, 206, 86)',		// yellow
		'rgba(255, 159, 64)',		// orange
		'rgba(153, 102, 255)',	// purple
		'rgba(201, 203, 207)',	// grey
		'rgba(255, 55, 132)',
		'rgba(247, 120, 37)',
		'rgba(0, 168, 19)',
		'rgba(55, 159, 122)',
		'rgba(204, 39, 56)',
		'rgba(139, 98, 138)',
		'rgba(2143, 190, 0)',
		'rgba(96, 96, 96)'
	],
	operatorId: {
		0: "=",
		1: "!=",
		2: "<",
		3: ">",
		4: ">=",
		5: "<="
	},
	DEFAULT_KPI_COUNT_BY: "Location.LocationId",
	daysDropDown: [
		{ LookupId: "-1", DisplayValue: "Custom" },
		{ LookupId: "7:day", DisplayValue: "Last 7 Days" },
		{ LookupId: "14:day", DisplayValue: "Last 14 Days" },
		{ LookupId: "30:day", DisplayValue: "Last 30 Days" },
		{ LookupId: "90:day", DisplayValue: "Last 90 Days" },
		{ LookupId: "1:year", DisplayValue: "Last Year" },
		{ LookupId: "0:currentWeek", DisplayValue: "Week to Date" },
		{ LookupId: "0:currentMonth", DisplayValue: "Month to Date" },
		{ LookupId: "0:currentQuarter", DisplayValue: "Quarter to Date" },
		{ LookupId: "0:currentYear", DisplayValue: "Year to Date" },
	],
	KPI: [
		{ LookupId: "7:day", DisplayValue: "OSA" },
		{ LookupId: "14:day", DisplayValue: "OOSTotal" },
		{ LookupId: "30:day", DisplayValue: "Facings" },
		{ LookupId: "90:day", DisplayValue: "Facing-days" },
	],
	getCountData: async (response, history) => {
		let { ClientId } = response ? response.tags : "";
		let params = { action: 'otherAction', otherAction: 'GetDataCount' };
		if (Number(ClientId) > 0) {
			params = { ...params, ...{ clientId: Number(ClientId) } }
		}
		const listResponse = await request({ url: apis.assetLatestImages, params, history, dispatch: store.dispatch });
		if (listResponse && listResponse.success) {
			store.dispatch({ type: actions.SET_VISTA_LIST_COUNT_DATA, listCountData: listResponse.data });
		}
	},

	getComboData: async (history) => {
		let params = {
			action: "load", isForAssignedSalesPerson: true
		};
		var comboTypes = [];
		for (var i = 0, len = comboStores.length; i < len; i++) {
			let comboObject = { type: comboStores[i], loaded: true }
			comboTypes.push(comboObject);
		}
		if (Array.isArray(comboTypes)) {
			params.comboTypes = JSON.stringify(comboTypes);
		}
		const [portalCombo, reportingToolCombo] = await Promise.all([request({ url: apis.Asset, params, history, dispatch: store.dispatch }), request({ url: apis.LoadCombo, params: { comboList: reportingToolCombos }, history, dispatch: store.dispatch, jsonPayload: true })]);
		if (portalCombo || reportingToolCombo) {
			store.dispatch({ type: actions.SET_COMBO_DATA, comboData: { ...portalCombo.combos, ...reportingToolCombo.combos } });
		}
	},
	afterLogin(userRoutes, history) {
		const searchParams = history.location ? history.location.search : "";
		let pathname = history.location.pathname;
		pathname = pathname.split("/")[1]; // extracting the base path in case of param passed in route
		pathname = '/' + pathname;
		if (!!pathname && pathname !== '/' && pathname !== '/login' && userRoutes.includes(pathname)) {
			let queryString = searchParams.replace('?redirectUrl=', "/").split('?');
			history.replace({ pathname: queryString[0], search: queryString.length > 1 ? queryString[1] : "" });
		}
		else {
			window.location.href = window.location.origin + "/#" + userRoutes[0];
		}
		this.getComboData(history);
	},

	reLogin: function (history) {
		let params = { method: 'GET', credentials: 'include', url: apis.login, };
		const transport = axios.create({ withCredentials: true });

		transport(params).then((response) => {
			console.log("login response =>", response)
			let sendToLogin = true;
			if (response && response.status === 200) {
				console.log("response.data.clientList =>", response.data.clientList)
				if (response.data.clientList) {
					console.log("inside 1st if",)

					store.dispatch({ type: actions.SET_CLIENT_LIST_DATA, clientsList: response.data.clientList });
					store.dispatch({ type: actions.SET_USER_EMAIL, userEmail: response.data.tags.Username, clientsList: response.data.clientList })
					history.replace({ pathname: "/login" });
				}
				else {
					console.log("inside 1st else =>")

					const userRoutes = this.getAssignedRoutes(response.data)
					if (response.data.message === "Logged in" && userRoutes.length) {
						sendToLogin = false;
						store.dispatch({ type: actions.SET_USER_DATA, userData: response.data });
						this.afterLogin(userRoutes, history, store.dispatch);
						if (response.data && response.data.tags && response.data.tags.Language) {
							localization.setLanguage(response.data.tags.Language);
						}
					}
					else {
						console.log("response.data.RedirectUrl =>", response.data.RedirectUrl)

						if (response.data.RedirectUrl) {
							console.log("redirecting to choosesso")
							history.replace({ pathname: "/choosesso" });
						}
						else {
							history.replace({ pathname: "/login" });
						}
					}
				}
			}
		});
	},

	sleep(milliseconds) {
		const date = Date.now();
		let currentDate = null;
		do {
			currentDate = Date.now();
		} while (currentDate - date < milliseconds);
	},

	hexCodeToRGBA(h) {
		h = h.replace('#', '');
		let r = 0, g = 0, b = 0;
		if (h.length === 6) {
			r = "0x" + h[1] + h[1];
			g = "0x" + h[2] + h[2];
			b = "0x" + h[3] + h[3];
		}

		return "rgba(" + +r + "," + +g + "," + +b + "," + .2 + ")";
	},
	string_ToRGB(str = '') {
		if (!str) {
			return;
		}
		let hash = 0;
		let length = str.length
		for (let i = 0; i < length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let colour = '#';
		for (let i = 0; i < 3; i++) {
			let value = (hash >> (i * 8)) & 0xFF;
			colour += ('00' + value.toString(16)).substr(-2);
		}
		return colour;
	},

	mapChildren(list) {
		let map = {}, node, roots = [], i, len = list.length;
		for (i = 0; i < len; i += 1) {
			map[list[i].AssetId] = i; // initialize the map
			list[i].children = []; // initialize the children
		}
		for (i = 0; i < len; i += 1) {
			node = list[i];
			if (node.ParentAssetId !== 0) {
				// if you have dangling branches check that map[node.parentId] exists
				if (list[map[node.ParentAssetId]]) {
					list[map[node.ParentAssetId]].children.push(node);
				}
			} else {
				roots.push(node);
			}
		}
		return roots;
	},
	round(num, precision, method) {

		// Convert string numbers to a float
		num = parseFloat(num);

		// If there's no rounding precision, return the number
		if (!precision) return num.toLocaleString();

		// Possible methods and their values
		var methods = {
			auto: 'round',
			up: 'ceil',
			down: 'floor'
		};

		// Get the method function
		var fn = methods[method];
		if (!fn) {
			fn = 'round';
		}

		// Do math!
		return (Math[fn](num / precision) * precision).toString();
	},
	getPrecisionValue(maxValue) {
		return maxValue <= 10 ? 5 : maxValue > 10 && maxValue <= 100 ? 10 : maxValue > 100 && maxValue <= 150 ? 20 : maxValue > 100 && maxValue <= 500 ? 50 : maxValue > 500 && maxValue <= 1000 ? 100 : 500;
	},
	// Number formatting eg -> 2324 -> 2,324
	formatNumValue(value) {
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},
	stringFormatter(cellData) {
		return cellData.length > 23 ? cellData.toString().substr(0, 23) + '...' : cellData;
	},
	extractMenu(menuArray, finalMenuArray) {
		for (let i = 0, arrayLength = menuArray && menuArray.length; i < arrayLength; i++) {
			if (menuArray[i].children && menuArray[i].children.length) {
				this.extractMenu(menuArray[i].children, finalMenuArray)
			} else {
				finalMenuArray.push(menuArray[i].url)
			}
		}
	},
	getAssignedRoutes(userData) {
		let tempArray = (userData && userData.menu && userData.menu.children) ? userData.menu.children : [];
		if (tempArray.length) {
			const result = tempArray.filter(obj => obj.id === this.dashboardMenuId);
			let menuArray = result[0] && result[0].children ? result[0].children : [], finalMenuArray = []
			const homeIndex = menuArray.findIndex((v) => v.text === "Home");
			if (homeIndex > 0) {
				const temp = menuArray[0];
				menuArray[0] = menuArray[homeIndex];
				menuArray[homeIndex] = temp;
			}
			this.extractMenu(menuArray, finalMenuArray)
			return finalMenuArray
		}
	},
	getFilterData(newData, OldData, isFromLog = false) {
		if (!newData.firstFilter && newData && newData.records && newData.records.length) {
			for (let d of newData.records) {
				let startIndex = newData.startIndex;
				let findIndexObj = OldData.records.findIndex(el => isFromLog ? el.Id == d.Id : el.LocationId == d.LocationId);
				if (findIndexObj > -1) {
					OldData.records[findIndexObj] = d;
				} else {
					d.startIndex = startIndex;
					OldData.records.push(d)
				}
			}
		}
		if (!newData.firstFilter) {
			OldData.recordCount = newData.recordCount;
			OldData.TotalCount = newData.TotalCount
			OldData.PlanogramComplianceAvg = newData.PlanogramComplianceAvg;
			OldData.OutOfStockAvg = newData.OutOfStockAvg;
		}
		return OldData;
	},
	getSubdomain() {
		let host = window.location.host;
		let subdomain = host.split('.')[0];
		return subdomain
	},
	getExportColumn(currencySymbol, key, colList) {
		let columns = colList;
		if (colList && colList.length > 0) {
			let dataIndex = colList.findIndex(e => e.dataIndex == key);
			if (dataIndex > -1) {
				columns[dataIndex].title = `${colList[dataIndex].title} (${currencySymbol || '*'})`;
			}
		}
		return columns;
	},
	isAdminORSuperAdmin(value) {
		return Number(value) == 1;
	},
	getReorderFilters(params, filterObj) {
		let newParams = Object.assign({}, params);
		if (filterObj.locationSearch) {
			newParams = { ...newParams, ...{ assetOrLocationName: filterObj.locationSearch } };
		}
		if (filterObj.ClientId && Number(filterObj.ClientId) > 0) {
			newParams = { ...newParams, ...{ clientId: Number(filterObj.ClientId) } }
		}
		if (filterObj.channel) {
			newParams = { ...newParams, ...{ locationTypeId: filterObj.channel } };
		}
		if (filterObj.level) {
			newParams = { ...newParams, ...{ classificationId: filterObj.level } };
		}
		if (filterObj.restockIn) {
			newParams = { ...newParams, ...{ restockScheduleDays: filterObj.restockIn } };
		}
		if (filterObj.filterType >= 0) {
			newParams = { ...newParams, ...{ restockFilterType: filterObj.filterType } };
		}
		if (filterObj.order) {
			newParams = { ...newParams, ...{ orderRange: filterObj.order } };
		}
		if (filterObj.cases) {
			newParams = { ...newParams, ...{ casesRange: filterObj.cases } };
		}
		if (filterObj.reorderLocationStatus) {
			newParams = { ...newParams, ...{ locationStatusId: filterObj.reorderLocationStatus } };
		}

		return newParams
	},
	isMobile() {
		return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
	},
	isEdge() {
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		return !isIE && !!window.StyleMedia;
	},
	handleFilterChange: (state, action) => {
		if (!action.target) {
			return { ...state, ...action };
		}
		let { name, value } = action && action.target;
		let newState = {};
		//Add Default filter type of equal if Value is exist in restock day filter
		if ((name === 'restockIn' && value) && !state.filterType) {
			Object.assign(newState, { filterType: '0' });
		} else if (name === 'restockIn' && Number(value) < 0) {
			newState.filterType = '';
		}
		//Assign default equal filter for Restock In dropdown
		if (Number(state.restockIn) > 0 && (name === 'filterType' && (value === "-1" || value === 0))) {
			value = '0';
		}
		return { ...state, ...newState, [name]: value };
	},
	convertMultipleLocale(itemList = [], t, tOpts) {
		const list = [];
		for (let items of itemList) {
			const item = { ...items };
			list.push({ ...item, DisplayValue: t(item.DisplayValue, tOpts) })
		}
		return list;
	},
	resetCheckedLocation(checkedData, dispatch) {
		if (checkedData && checkedData.length > 0) {
			dispatch({ type: actions.SET_OPERATIONS_INFORMATION, operationsCheckedData: { checkedData: [] } });
		}
	},
	onScheduledIgnoreClick({ e, isMulti, data, statusId, getData, isReorder, dispatch, history, checkedData, t, tOpts, successCBK, addNotes = false }) {
		e.preventDefault();

		function location_has_latest_asset_data(rowData) {

			return ((rowData.TotalAssetCount - rowData.AssetCountWithOldImage) > 0);
		}


		const onChangeStatus = async (params, isReorder = false) => {
			const { lineItems, ScheduledOrderItem } = params;
			if (isReorder && ((!lineItems || (lineItems == "[]")) && !ScheduledOrderItem)) {
				toast.error(t("No active location to move.", tOpts), toastConfig);
				return;
			}
			const response = await request({ url: apis.ScheduledOrder, params, history, dispatch });
			if (response) {
				this.resetCheckedLocation(checkedData, dispatch);
				if (getData)
					getData({ startIndex: 0, isFirstFilter: true });
				if (successCBK)
					successCBK()
				//TODO
				// if (typeof props.onStatusChange == 'function') {
				//     props.onStatusChange(new Date())
				// }
			}
		}

		const getMutliSelectParams = (isReorderGrid, statusId) => {
			let params = {}
			let lineItem = [];
			let filtered = data.records.filter(el => { return checkedData.indexOf(el.LocationId) > -1 && el.IsActive; });

			if (isReorderGrid) {
				Object.assign(params, { action: 'otherAction', otherAction: "SaveReorderMultipleLocation" });
				filtered.map(e => {
					let isLatest = location_has_latest_asset_data(e);
					lineItem.push(
						{
							ScheduledOrderId: e.Id || 0,
							StatusId: statusId,
							LocationId: e.LocationId,
							OutOfStock: e.OutOfStock,
							ScheduledOrderItem: e.ProductInfo,
							AssetCountWithOldImage: e.AssetCountWithOldImage,
							TotalAssetCount: e.TotalAssetCount,
							OrderMovementTypeId: MOVEMENT_STATUS.MANUAL,
							OrderSize: isLatest ? e.LatestAssetImageTotalOrder : e.TotalOrder,
							TotalCaseSize: isLatest ? e.LatestAssetImageTotalCase : e.TotalCase,
							RestockIn: e.RestockIn || 0,
							Notes: addNotes ? e.Notes : null
						}
					)
				})
			} else if (!isReorderGrid) {
				Object.assign(params, { action: 'otherAction', otherAction: "MarkMultipleScheduleOrIgnore" });
				filtered.map(e => {
					lineItem.push(
						{
							Id: e.Id || 0,
							ScheduledOrderId: e.Id || 0,
							StatusId: statusId,
							OutOfStock: e.OutOfStock,
							LocationId: e.LocationId,
							AssetCountWithOldImage: e.AssetCountWithOldImage,
							TotalAssetCount: e.TotalAssetCount,
							OrderMovementTypeId: MOVEMENT_STATUS.MANUAL
						})
				})
			}
			params.lineItems = JSON.stringify(lineItem);
			return params;
		}

		let title = `Do you want to ${statusId == ORDER_STATUS.SCHEDULED ? 'schedule' : statusId == ORDER_STATUS.INTRANSIT ? 'In-Transit' : statusId == ORDER_STATUS.INBOX ? 'go to Inbox' : 'ignore'} order?`
		swal({
			title: t(title, tOpts), icon: "info", buttons: { cancel: t("Cancel", tOpts), confirm: t("OK", tOpts), }, dangerMode: true,
		}).then(async (isConfirm) => {
			if (isConfirm) {
				let params = {};
				const rowData = data[0];
				if (!isMulti) {
					Object.assign(params, {
						StatusId: statusId, ScheduledOrderId: rowData.Id, LocationId: rowData.LocationId, OutOfStock: rowData.OutOfStock, AssetCountWithOldImage: rowData.AssetCountWithOldImage,
						TotalAssetCount: rowData.TotalAssetCount, OrderMovementTypeId: MOVEMENT_STATUS.MANUAL
					});
					if (isReorder) {
						let isLatest = location_has_latest_asset_data(rowData);
						const { ProductInfo } = rowData;
						Object.assign(params, {
							OrderSize: isLatest ? rowData.LatestAssetImageTotalOrder : rowData.TotalOrder,
							TotalCaseSize: isLatest ? rowData.LatestAssetImageTotalCase : rowData.TotalCase,
							RestockIn: rowData.RestockIn || 0,
							AssetPurityIds: ProductInfo[0] && ProductInfo[0].AssetPurityIds || ''
						});
					}
				}
				let multiSeleteParams = {};
				if (isReorder && isMulti) {
					multiSeleteParams = getMutliSelectParams(isReorder, statusId);
				} else if (!isReorder && isMulti) {
					multiSeleteParams = getMutliSelectParams(isReorder, statusId);
				} else if (isReorder) {
					Object.assign(params, { action: 'save', ScheduledOrderItem: rowData ? JSON.stringify(rowData.ProductInfo) : [] });
				} else {
					Object.assign(params, { action: "otherAction", otherAction: "MarkScheduleOrIgnore" });
				}
				onChangeStatus(isMulti ? multiSeleteParams : params, isReorder);
			}
		});
	},

	onActivateDeactivateLocation({ event, locationIds = [], statusId = 0, history, dispatch, getData, t, tOpts }) {
		event.preventDefault();
		if (!locationIds || locationIds.length == 0) {
			toast.error(t("No location selected.", tOpts), toastConfig);
			return;
		}
		let alertTitle = `Do you want to ${(!statusId || statusId == 2) ? 'de-activate' : 'activate'} the location ?`;

		swal({
			title: t(alertTitle, tOpts),
			icon: "info",
			buttons: true,
			dangerMode: true
		}).then(async (isConfirm) => {
			if (isConfirm) {
				let params = {};
				Object.assign(params, {
					LocationIds: locationIds,
					StatusId: statusId,
					action: 'otherAction',
					otherAction: 'ActivateDeactivateLocation'
				});
				const res = await request({ url: apis.ReorderDetail, params, history, dispatch });
				const { info, data, success } = res;
				if (success) {
					// reset location selection
					this.resetCheckedLocation(locationIds, dispatch);
					if (data) {
						toast.success(info, toastConfig);
						getData({ startIndex: 0, isFirstFilter: true });
					} else {
						toast.error(info, toastConfig);
					}

				} else {
					toast.error(t("Something went wrong, please try again.", tOpts), toastConfig);
				}
			}
		});
	},
	async getMetricFilters({ history, dispatch, oldComponent = false }) {
		let response = await request({ url: apis.KPIConfig, history, dispatch });
		response = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
		response = response.filter((item) => {
			if (oldComponent) {
				return item.dashboardView === true && !(this.kpiTables.assetDayKPI.includes(item.dataKey)); // this check is added for old component so that error does not comes for admin accounts if they open ECCBC dashboard with unilever client
			} else {
				return item.dashboardView === true
			}
		})
		return response;
	},
	getFilters({ metrics, groupBy = this.DEFAULT_KPI_GROUP_BY, kpiFilters, countBy = this.DEFAULT_KPI_COUNT_BY, sort, sortKey, dateFormat = "" }) {
		try {
			let filterParams = {
				where: {},
				measures: {},
				groupBy: []
			};
			const {
				marketIds = [],
				classificationIds = [],
				channelIds = [],
				fromDate,
				toDate,
				managerSelectedValue = [],
				daysFilter,
				customFilter = {}
			} = kpiFilters;

			let filterGroupBy = [];
			groupBy.filter((k) => {
				if (k.value === true) {
					filterGroupBy.push(k.dataKey);
				}
			});

			let filterMetrics = metrics.filter((m) => m.checked === true) || [];
			if (fromDate)
				filterParams.where.PurityDateTimeFrom = { value: dateFormat ? dayjs(fromDate).format('YYYY-MM-DD') : new Date(new Date(fromDate).setHours(0, 0, 0, 0)), fieldName: "PurityDateTime", operator: ">=" };
			if (toDate)
				filterParams.where.PurityDateTimeTo = { value: dateFormat ? dayjs(toDate).format('YYYY-MM-DD') : new Date(new Date(toDate).setHours(23, 59, 59, 999)), fieldName: "PurityDateTime", operator: "<=" };

			if (classificationIds.length && classificationIds.join(",") !== "-1") {
				// adding classification filter
				Object.assign(filterParams.where, {
					ClassificationId: {
						value: classificationIds
					}
				});
			}
			if (marketIds.length && marketIds.join(",") !== "-1") {
				// adding markets filter
				Object.assign(filterParams.where, {
					MarketId: {
						value: marketIds
					}
				});
			}
			if (channelIds.length && channelIds.join(',') !== '-1') {
				// adding channel filter
				Object.assign(filterParams.where, {
					LocationTypeId: {
						value: channelIds
					}
				});
			}
			if (managerSelectedValue && managerSelectedValue.length) {
				let salesManagerIds = managerSelectedValue.map(m => m.LookupId || m);
				// adding salesRep filter
				if (salesManagerIds.join(',') !== '-1') {
					Object.assign(filterParams.where, {
						FirstRepId: {
							value: salesManagerIds
						}
					});
				}
			}
			if (Object.keys(customFilter).length) {
				Object.assign(filterParams.where, {
					...customFilter
				});
			}

			// adding groupBy params
			if (countBy) {
				filterGroupBy.push(countBy)
			}
			Object.assign(filterParams, { groupBy: filterGroupBy });

			if (filterMetrics.length) {
				// adding measures filter
				Object.assign(filterParams.measures, this.metricsMeasures(filterMetrics));
			}
			//add sorting
			if (sort) {
				Object.assign(filterParams, { sort })
			}
			//add sort key
			if (sortKey) {
				Object.assign(filterParams, { sortKey })
			}
			return filterParams;
		} catch (e) {
			console.error(e)
		}
	},
	metricsMeasures(matrics) {
		let filterMatrics = matrics.filter((m) => m.checked === true);
		var measure = {};
		filterMatrics.map((data) => {
			let key = data.dataKey;
			let val = {
				calc: data.metricsValue,
				ranges: data.ranges,
			};
			measure = {
				...measure,
				[key]: val,
			};
		});
		return measure;
	},
	async getData({ metricsFilter, kpiFilters, countBy = this.DEFAULT_KPI_COUNT_BY, groupBy = this.DEFAULT_KPI_GROUP_BY, preDefineFilters, dispatch, history, t, tOpts }) {
		const params = preDefineFilters ? preDefineFilters : this.getFilters({ metrics: metricsFilter, kpiFilters, countBy, groupBy });
		const validation = { status: false, title: "" };

		if (!(metricsFilter && metricsFilter.length)) {
			validation.status = true;
			validation.title = 'Unable to fetch client metrics, Please retry';
		}
		if (!(groupBy && groupBy.length)) {
			validation.status = true;
			validation.title = 'Group by values not found, Please retry';
		}
		if (!(params && Object.keys(params).length)) {
			validation.status = true;
			validation.title = 'Unable to generate filters, Please retry';
		}

		groupBy = groupBy.filter((group) => group.value === true);
		metricsFilter = metricsFilter.filter((m) => m.checked === true);

		if (!groupBy.length) {
			validation.status = true;
			validation.title = 'Select at least one method of group by, Please retry';
		}

		if (!metricsFilter.length) {
			validation.status = true;
			validation.title = 'Select at least one metric, Please retry';
		}


		if (validation.status) {
			swal({ title: t(validation.title, tOpts), text: "", icon: "error", dangerMode: false });
			return;
		}

		const pageTittleLocal = this.genPageTitle({ groupBy, metrics: metricsFilter, countBy, dispatch });

		if (!loading) {
			loading = true;
			const response = await request({ url: apis.KPIData, params, history, dispatch, jsonPayload: true });
			if (response) {
				dispatch({
					type: actions.SET_KPI_DATA,
					kpiData: {
						totalCount: response.totalRecords,
						data: response.records,
						groupBy: groupBy,
						metrics: metricsFilter,
						where: params.where,
						countBy,
						pageTitle: pageTittleLocal,
						timeString: Date.now()
					},
				});
			}
			loading = false;
		}
	},
	genPageTitle({ groupBy, metrics, countBy, dispatch }) {
		const groupByName = [], metricsName = [];
		for (const element of groupBy) {
			if (element.value === true) {
				groupByName.push(element.dataKey);
			}
		}
		for (const element of metrics) {
			if (element.checked === true) {
				metricsName.push(element.label);
			}
		}
		dispatch({
			type: actions.SET_KPI_PAGE_TITLE,
			pageTitle: {
				title: `${countBy.split('.')[0]} - ${groupByName.join()} - ${metricsName.join()} `,
				desc: `${countBy.split('.')[0]} group by '${groupByName.join()}', Metrics - '${metricsName.join()}' `
			},
		});

		return `${countBy.split('.')[0]} - ${groupByName.join()} - ${metricsName.join()} `;
	},
	getCurrencyFormat(number) {
		let currNum = parseInt(number);
		currNum = currNum.toLocaleString();
		return currNum;
	},
	TOpts: () => {
		const { t: translate, i18n } = useTranslation()
		const tOpts = { t: translate, i18n };
		return tOpts
	},
	t(sentence, i18nNext, options = {}) {
		if (!i18nNext) {
			return sentence;
		}
		const { t, i18n } = i18nNext;
		if (!(t || i18n)) {
			return sentence;
		}
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		const isEdge = !isIE && !!window.StyleMedia;

		// Additional condition added for Edge and Firefox as they do not return only en instead return en-IN
		if (i18n?.language === "en" || i18n?.language.split('-')[0] === "en") {
			return t(sentence, options);
		}
		const optionKeys = Object.keys(options);
		let loweredSentence = [];
		// In case of non en language do not lowercase the variable , as lowercase it will result in value not updating in string
		if (optionKeys.length) {
			loweredSentence = sentence.split(" ");
			loweredSentence = loweredSentence.map((item) => {
				if (item.includes("{{") && item.includes("}}") && isEdge) {
					return item;
				} else {
					return item.toLowerCase();
				}
			})
		}
		const tString = loweredSentence.length ? loweredSentence.join(" ") : sentence.toString().toLowerCase();
		const transformed = t(tString, options);
		if (sentence === sentence.toString().toUpperCase()) {
			return transformed.toString().toUpperCase()
		} else if (sentence === sentence.toString().toLowerCase()) {
			return transformed.toString().toLowerCase();
		} else {
			return transformed[0].toUpperCase() + transformed.substring(1)
		}
	},

	formatDataGridFilters(gridFilters) {
		const fieldName = {}
		if (gridFilters.fieldName == "EndDate") {
			if (gridFilters.operatorId == "DATE_RANGE") {
				fieldName["startDate"] = gridFilters.values[0];
				fieldName["endDate"] = gridFilters.values[1];
				fieldName["OperatorId"] = "<=";
			} else {
				fieldName["OperatorId"] = utils.operatorSign[gridFilters.operatorId];
				fieldName["endDate"] = gridFilters.values[0];
			}
		} else {
			fieldName[gridFilters.fieldName] = gridFilters.values[0];
		}
		return fieldName;
	},

	filterChecker(items) {
		let newItems = items.filter((item) => {
			return (item.value || ["isEmpty", "isNotEmpty"].includes(item.operatorValue));
		});
		return newItems;
	},

	checkForDateValue(item) {
		const newDate = new Date(item);
		if (newDate.toString() === "Invalid Date") {
			return false;
		} else {
			return true;
		}
	},
	createFilter(modelFilter, typeExport = false) {
		if (!modelFilter || !modelFilter.items) {
			return false;
		}
		const items = this.filterChecker(modelFilter.items);
		let filters = {}, operator = modelFilter.linkOperator.toUpperCase();

		if (typeExport) {
			const newFilters = items.map((item) => {
				const isValueADate = this.checkForDateValue(item.value);
				const newItem = {};
				newItem[item.columnField] = item.value;
				newItem["operatorValue"] = item?.operatorValue || "";
				newItem["isValueADate"] = isValueADate;
				return newItem
			})
			return newFilters;
		}

		items.map((item, key) => {
			const newItem = { fieldName: item.columnField, operatorId: this.filterType[item.operatorValue], convert: false, values: Array.isArray(item.value) ? item.value : [item.value] }
			if (!items[1]) {
				filters = newItem;
				return;
			}
			switch (key) {
				case 0:
					filters["left"] = newItem;
					break;
				case 1:
					filters["logicalOperator"] = operator;
					filters["right"] = newItem;
					break;
				default:
					let newFilter = {};
					if (!filters["right"] && filters["left"]) {
						newFilter = { ...filters };
						newFilter["logicalOperator"] = operator;
						newFilter["right"] = newItem;
					} else if (filters["right"] && filters["left"]) {
						newFilter = { ...filters };
						newFilter["left"] = filters;
						newFilter["logicalOperator"] = operator;
						newFilter["right"] = newItem;
					}
					filters = { ...newFilter };
					break;
			}
		})
		return filters;
	},
	addToFilter(filter, item, operator) {
		let newFilter = {};
		operator = operator.toUpperCase()
		if (Object.keys(filter).length === 0) {
			return item;
		} else if (!filter["right"] && filter["left"]) {
			newFilter = { ...filter };
			newFilter["logicalOperator"] = operator;
			newFilter["right"] = item;
		} else if (filter["right"] && filter["left"]) {
			newFilter = { ...filter };
			newFilter["left"] = filter;
			newFilter["logicalOperator"] = operator;
			newFilter["right"] = item;
		} else {
			newFilter["left"] = { ...filter };
			newFilter["logicalOperator"] = operator;
			newFilter["right"] = item;
		}
		return newFilter;
	},
	IS_ACTIVE: "IsActive",
	BOOLEAN_FIELDS: ["IsActive", "IsForeign", "IsChestCooler"],
	valueMappings: {
		"yes": true,
		"no": false,
		"vertical": false,
		"horizontal": true
	},
	DEFAULT_PAGE: 0,
	DEFAULT_PAGE_SIZE: 10,
	defaultFilter: { where: {}, isGridFilter: false, sort: {}, linkOperator: '', pagination: { page: 0, pageSize: 10 } },
	sqlTypeMappings: {
		"Latitude": "int",
		"Longitude": "int",
		"IsActive": "boolean",
		"IsForeign": "boolean",
		"IsChestCooler": "boolean",
		"Shelves": "int",
		"Columns": "int",
		"AssignedAssetsCount": "int",
		"Facings": "int",
		"FacingsDistinct": "int",
		"CreatedOn": "date",
		"lastDelivery": "date",
		"TotalCaseSize": "int",
		"OrderSize": "int",
		"TotalCase": "int",
		"TotalOrder": "int"
	},
	sortMappings: {
		"Address": "Street"
	},
	allowedNullValueOperators: ["IS", "IS NOT"],
	allowedTypesForNull: ["number", "boolean"],
	onFilterModelChange({ setFilters, setFilterModal, filterModalP, ...rest }) {
		return (filterModel) => {
			if (setFilters && setFilterModal && !Array.isArray(filterModel)) {
				setFilterModal({ ...filterModalP, [rest.item]: filterModel })
			}
			const where = {};
			if (!filterModel) return;
			let extra = {};
			if (Array.isArray(filterModel)) {
				const model = filterModel[0];
				if (!model) {
					extra = { sort: {}, isGridFilter: true, ...rest }
				} else {
					const { sort, field } = model || {};
					extra = { sort: { field: this.sortMappings[field] || field, sort }, isGridFilter: true, ...rest }
				}
			} else {
				const { linkOperator = "AND", items = [], pagination, returnOnlyValue = false } = filterModel || {};
				for (let item of items) {
					let { columnField, value, operatorValue } = item;
					let { operator = operatorValue, value: tempValue } = utils.gridFilterOperatorMappings[operatorValue] || {};
					if (this.BOOLEAN_FIELDS.includes(columnField)) {
						tempValue = typeof value === "boolean" ? value : this.valueMappings[value?.toLowerCase()]
					} else {
						tempValue = tempValue && value ? tempValue.replace(/@value/, value) : value;
					}
					if (!this.allowedNullValueOperators.includes(operator) && !tempValue && !this.allowedTypesForNull.includes(typeof tempValue)) {
						continue;
					}
					if (!where[columnField]) {
						where[columnField] = []
					}
					where[columnField].push({ value: tempValue, operator, sqlType: this.sqlTypeMappings[columnField] });
				}
				extra = { where, linkOperator, isGridFilter: true, ...rest }
				if (pagination) {
					extra.pagination = pagination;
				}
				if (returnOnlyValue) {
					return extra;
				}
			}

			if (rest?.item) {
				const { item } = rest;
				setFilters(prev => ({ ...prev, item, [item]: { ...(prev[item] || {}), ...extra } }));
				return;
			}
			setFilters(prev => ({ ...prev, ...extra }));
		}
	},
	getDateFilter(selectedValue, dateFilterMaxDate) {
		const result = { fromDate: '', toDate: dateFilterMaxDate ? dateFilterMaxDate : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) }, tempSplit = selectedValue.split(':');
		const value = tempSplit[0], range = tempSplit[1];
		switch (range) {
			case "day":
				result.fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - Number(value));
				break;
			case "year":
				result.fromDate = new Date(new Date().getFullYear() - Number(value), new Date().getMonth(), new Date().getDate());
				break;
			case "currentWeek":
				let weekDate = new Date();
				weekDate = weekDate.getDate() - weekDate.getDay();
				result.fromDate = new Date().setDate(weekDate);
				break;
			case "currentMonth":
				result.fromDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
				break;
			case "currentYear":
				result.fromDate = new Date(new Date().getFullYear(), 0, 1);
				break;
			case "currentQuarter":
				const currentMonth = (new Date()).getMonth();
				const yyyy = (new Date()).getFullYear();
				const start = (Math.floor(currentMonth / 3) * 3) + 1;
				result.fromDate = new Date(start + '-01-' + yyyy);
				break;
			default:
		}
		return result;
	},
	metricCategory: {
		ShareOfShelf: "Share of Shelf",
		ProductAssortment: "Product Assortment",
		ProductAvailability: "Product Availability",
		ShelfLayout: "Shelf Layout",
		SalesAndShopper: "Sales And Shopper",
		Value: "Value",
		Cases: "Cases",
		Sales: "Sales",
		Compliance: "Compliance"
	},
	metricsCategoryMappings() {
		const metricCategory = utils.metricCategory;
		return {
			"KPI6": metricCategory.ProductAvailability,
			"KPI10": metricCategory.ShareOfShelf,
			"ForeignProductPercentage": metricCategory.ShareOfShelf,
			"EmptyProductPercentage": metricCategory.ShareOfShelf,
			"AssortmentDepth": metricCategory.ProductAvailability,
			"TargetPortfolioCompliance": metricCategory.Compliance,
			"PlanogramCompliance": metricCategory.Compliance,
			"FacingCompliance": metricCategory.Compliance,
			"CaseSuggested": metricCategory.Cases,
			"DoorOpenDailyCount": metricCategory.Sales,
			"ValueSuggested": metricCategory.Value,
		}
	},
	deepCloneObject(object) { //https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy
		return JSON.parse(JSON.stringify(object));
	},
	getCookie(cname) {
		const name = cname + "=";
		const decodedCookie = decodeURIComponent(document.cookie);
		const ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}

			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},
	operationalStatusImages: {
		"Installed": require(`../assets/images/installed.png`),
		"NotInstalled": require(`../assets/images/notInstalled.png`),
		"ModemNotPinging": require(`../assets/images/modem.png`),
		"CameraNotPinging": require(`../assets/images/camera.png`),
		"CameraNotTakingImages": require(`../assets/images/takePhoto.png`),
		"PlanogramNotAssigned": require(`../assets/images/freezerWithProduct.png`),
		"Working": require(`../assets/images/checked.png`)
	},
	distanceBTWCoordinates(p1, p2) {
		const rad = function (x) {
			return x * Math.PI / 180;
		}

		p1.latitude = p1.lat ? p1.lat() : p1.Latitude ? p1.Latitude : p1.latitude || 0;
		p1.longitude = p1.lng ? p1.lng() : p1.Longitude ? p1.Longitude : p1.longitude || 0;
		p2.latitude = p2.lat ? p2.lat() : p2.Latitude ? p2.Latitude : p2.latitude || 0;
		p2.longitude = p2.lng ? p2.lng() : p2.Longitude ? p2.Longitude : p2.longitude || 0;

		const radius = 6378137, // Earth’s mean radius in meter,
			dLat = rad((p2.latitude) - (p1.latitude)),
			dLong = rad((p2.longitude) - (p1.longitude)),
			area = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.latitude)) *
				Math.sin(dLong / 2) * Math.sin(dLong / 2),
			calc = 2 * Math.atan2(Math.sqrt(area), Math.sqrt(1 - area)),
			distance = radius * calc;
		return distance; // returns the distance in meter
	},
	columnsDataTypes: {
		"Latitude": "numeric",
		"Longitude": "numeric",
		"IsActive": "boolean",
		"IsForeign": "boolean",
		"IsChestCooler": "boolean",
		"Shelves": "numeric",
		"Columns": "numeric",
		"AssignedAssetsCount": "numeric",
		"Facings": "numeric",
		"FacingsDistinct": "numeric",
		"CreatedOn": "date",
		"lastDelivery": "date",
		"TotalCaseSize": "numeric",
		"OrderSize": "numeric",
		"TotalCase": "numeric",
		"TotalOrder": "numeric"
	},
	operatorMappings: {
		"contains": {
			operator: "like"
		},
		"startsWith": {
			operator: "like"
		},
		"endsWith": {
			operator: "like"
		},
		"equals": {
			operator: "eq"
		},
		"isAnyOf": {
			operator: "eq"
		},
		"isNotEmpty": {
			operator: "eq"
		},
		"isEmpty": {
			operator: "IS"
		},
		"IsActive": "equals"
	},
	getFilter: function (filterValues) {
		const updatedFilter = [];
		filterValues.forEach(({ columnField, id, operatorValue, value }) => {
			let operator = this.operatorMappings[operatorValue];
			operator = operator && operator.operator || "eq"
			updatedFilter.push({
				property: columnField,
				operator,
				value,
				type: this.columnsDataTypes[columnField] || "string"
			});
		});
		return updatedFilter;
	},
	listDefaultParams: {
		action: "list",
		asArray: 0,
		start: 0,
		limit: 10,
		filter: []
	},
	getMergedFilters: function ({ params, globalFilter = {} }) {
		const filters = {};
		const keys = Object.keys(globalFilter);
		keys.forEach(key => {
			const value = globalFilter[key];
			switch (key) {
				case "classificationIds":
					Object.assign(filters, { ClassificationId: value });
					break;
				case "marketIds":
					Object.assign(filters, { MarketId: value });
					break;
				case "channelIds":
					Object.assign(filters, { LocationTypeId: value });
					break;
				case "managerSelectedValue":
					Object.assign(filters, { SalesRepId: value });
					break;
				default:
					Object.assign(filters, { [key]: value });
					break;
			}
		});
		delete filters["changedKey"];
		return { ...params, ...filters };
	},
	isModuleExists(userData, module) {
		const userDataL = userData || {}, modules = userDataL.modules || {};
		return modules[module] && modules[module].Module;
	},
	generateUUID() { // Public Domain/MIT
		var d = new Date().getTime();//Timestamp
		var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16;//random number between 0 and 16
			if (d > 0) {//Use timestamp until depleted
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {//Use microseconds since page-load if supported
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	},
	productGridFilterOptions: { operator: "equals" },
	filterAllIssues(callback) {
		return <Button variant="text" onClick={callback} className='grid-toolbar-button'><ErrorOutlineIcon className='grid-toolbar-icon' />Filter All Issues</Button>
	},
	autoExpendRow(gridRef, editProductRowId, setExpandedRowId) {
		const rows = gridRef.current.state.rows.idRowsLookup;
		for (const key in rows) {
			const row = rows[key];
			if (row.RowNumber === editProductRowId) {
				setExpandedRowId(key)
				break;
			}
		}
	},
	fileExport({ form, params, columns, currencySymbol, format = "XLSX", title, fileName = title }) {
		let _params = utils.deepCloneObject(params);
		let additionalParams = "forExport=true";
		form.setExportParams({
			ExportCols: utils.getExportColumn(currencySymbol, 'Amount', JSON.parse(JSON.stringify(columns))),
			filters: _params,
			title: title,
			fileName: fileName,
			format: format,
			additionalParams: additionalParams
		});
	},
	updateFilter(payload = {}, filterModel, globalFilter, overviewRecordSelect) {
		payload.filter = utils.createFilter(filterModel);
		if (overviewRecordSelect && overviewRecordSelect.code) {
			payload.filter = [{ "property": "Code", "operator": "like", "value": overviewRecordSelect.code, "type": "string" }]
		}
		const _payload = utils.getMergedFilters({ params: payload, globalFilter });
		return _payload;
	},
	AssessmentType: {
		Radio: 'radio',
		Checkbox: 'checkbox',
		Text: 'text',
		Textarea: 'textarea',
		Select: 'select',
		File: 'file',
		MultiFile: 'multiFile',
		Number: 'number',
		barcode: 'barcode',
		Scene: 'scene',
		MultiFileStitch: 'multiFileStitch'
	},

	SurveyCreator_AssessmentType: {
		Radiogroup: 'radiogroup',
		Checkbox: 'checkbox',
		Text: 'text',
		Comment: 'comment',
		Dropdown: 'dropdown',
		File: 'file',
		MultiFile: 'multiFile',
		Number: 'number',
		Barcode: 'barcode',
		Scene: 'scene',
		MultiFileStitch: 'multiFileStitch'
	},
	convertToSurveyCreatorJSON: function (jsonConfg) {
		const surveyJsConfig = {};
		let sections = jsonConfg["sections"];
		const surveyJSSection = [];

		for (const sec of sections) {

			const questions = sec["questions"];
			const sectionElements = [];
			let surveyJSQ = {};
			for (const q of questions) {
				const dependency = q.dependentQuestion;
				let visibleIf = [];
				if (dependency && dependency.length) {
					for (const dep of dependency) {

						questions.map((q, index) => {
							if (q.name === dep.questionName) {

								const allAsnswersKey = q.options.map(op => op.key);
								const dependencykeys = dep.answerkey;
								allAsnswersKey.map((key) => {

									if (dependencykeys.indexOf(key) === -1) {
										visibleIf.push(`{${q.name}} = ${key}`)
									}
								})
							}
						});
					}


				}

				const options = q.options;
				const type = q.type;
				surveyJSQ = {
					"id": q.id,
					"name": q.id,
					"title": q.text,
					"isRequired": q.required,
					"info": q.info,
					"dynamicOptions": q.dynamicOptions && JSON.stringify(q.dynamicOptions),
					"dependentQuestion": q.dependentQuestion && JSON.stringify(q.dependentQuestion),
					"options": JSON.stringify(options)
				}

				if (options && options.length) {
					surveyJSQ["choices"] = [];
					for (const op of options) {
						surveyJSQ["choices"].push({ "text": op.value, "value": op.key });
					}
				}
				if (visibleIf && visibleIf.length) {
					surveyJSQ["visibleIf"] = visibleIf.toString().replace(/,/gi, " or ");
				}
				switch (type) {

					case this.AssessmentType.Radio:
						surveyJSQ["type"] = "radiogroup";
						break;
					case this.AssessmentType.Checkbox:
						surveyJSQ["type"] = "checkbox";
						break;
					case this.AssessmentType.Text:
						surveyJSQ["type"] = "text";
						break;
					case this.AssessmentType.Textarea:
						surveyJSQ["type"] = "comment";
						break;
					case this.AssessmentType.Select:
						surveyJSQ["type"] = "dropdown";
						break;
					case this.AssessmentType.File:
						surveyJSQ["type"] = "file";
						break;
					case this.AssessmentType.MultiFile:
						surveyJSQ["type"] = "file";
						surveyJSQ["allowMultiple"] = true;
						break;
					case this.AssessmentType.Number:
						surveyJSQ["type"] = "text";
						surveyJSQ["inputType"] = "number";
						break;
					case this.AssessmentType.barcode:
						surveyJSQ["type"] = "barcode";
						break;
					case this.AssessmentType.Scene:
						surveyJSQ["type"] = "scene";
						break;
					case this.AssessmentType.MultiFileStitch:
						surveyJSQ["type"] = "checkbox";
						break;

				}
				sectionElements.push(surveyJSQ);
			}
			surveyJSSection.push({
				"id": sec.id,
				"type": "panel",
				"title": sec.title,
				"state": "expanded",
				"secItems": (typeof (sec?.items) === "object") && JSON.stringify(sec.items),
				"elements": sectionElements
			})
		}

		surveyJsConfig["elements"] = surveyJSSection;
		return surveyJsConfig;
	},


	convertToCoolRJSON: function (jsonConfig) {

		const CoolrConfig = {};
		let sections = jsonConfig["pages"][0]["elements"];
		const CoolrSection = [];

		for (const sec of sections) {

			const questions = sec["elements"];
			const questionElements = [];
			let CoolrQ = {};
			for (const q of questions) {
				let options;
				try {
					options = options && JSON.parse(q?.options)
				} catch (error) {
					console.error(error);
				}
				const choices = q.choices;
				const type = q.type;
				CoolrQ = {
					"id": q?.name,
					"name": q?.name || "",
					"text": q?.title || "",
					"required": q?.isRequired,
					"info": q?.info || "",
					"dynamicOptions": q?.dynamicOptions && JSON.parse(q?.dynamicOptions),
					"dependentQuestion": q?.dependentQuestion && JSON.parse(q?.dependentQuestion),
				}

				if (choices && choices.length) {
					CoolrQ["options"] = options;
					CoolrQ["options"] = [];
					for (const ch of choices) {
						let optionKey = ch.value && ch.value.match(/(\d+)/)[0];
						CoolrQ["options"].push({ "value": ch.text, "key": optionKey ? optionKey : ch.value });
					}
				}

				switch (type) {

					case this.SurveyCreator_AssessmentType.Radiogroup:
						CoolrQ["type"] = "radio";
						break;
					case this.SurveyCreator_AssessmentType.Checkbox:
						CoolrQ["type"] = "checkbox";
						break;
					case this.SurveyCreator_AssessmentType.Text:

						if (q["inputType"] === "number") {
							CoolrQ["type"] = "number";
						} else {
							CoolrQ["type"] = "text";
						}
						break;
					case this.SurveyCreator_AssessmentType.Comment:
						CoolrQ["type"] = "textarea";
						break;
					case this.SurveyCreator_AssessmentType.Select:
						CoolrQ["type"] = "select";
						break;
					case this.SurveyCreator_AssessmentType.File:
						//  CoolrQ["allowMultiple"] = true;
						if (q["allowMultiple"]) {
							CoolrQ["type"] = "file";
						} else {
							CoolrQ["type"] = "multiFile";
						}
						break;
					case this.SurveyCreator_AssessmentType.Barcode:
						CoolrQ["type"] = "barcode";
						break;
					case this.SurveyCreator_AssessmentType.Scene:
						CoolrQ["type"] = "scene";
						break;

				}
				questionElements.push(CoolrQ);
			}

			let secItems = null;
			try {
				secItems = JSON.parse(sec.secItems);
			} catch (err) {
				secItems = sec.secItems;
			}
			CoolrSection.push({
				"id": sec.id,
				"title": sec.title || "",
				"items": secItems || "assets",
				"questions": questionElements
			})

		}

		CoolrConfig["sections"] = { "sections": CoolrSection };
		CoolrConfig["survey"] = { SurveyName: jsonConfig.title, TypeId: jsonConfig.SurveyType, SurveyId: jsonConfig.SurveyId, CategoryId: jsonConfig.SurveyCategory }
		return CoolrConfig;
	},

	localStorageName: "coolrsurvey",
	creatorOptions: {
		questionTypes: ["text", "comment", "checkbox", "radiogroup", "dropdown", "file", "panel", "scene", "barcode"]
	},
	dayjsFormatLocal: function ({ value, format, tOpts, lang }) {
		const langL = lang ? lang : tOpts?.i18n?.language?.slice(0, 2);
		return dayjs(value).locale(langL).format(format);
	},
	numberWithCommas(num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}

export default utils;
