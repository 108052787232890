import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';

let InitializeJSLogger = {
    initialize: function () {
      window.JsLoggerEx && window.JsLoggerEx.initialize();
    }
  }
  InitializeJSLogger.initialize();

ReactDOM.render(
    <React.StrictMode>
      <AddToHomeScreen
        appId='com.coolr.dashboard'
        startAutomatically={true}
        startDelay={0}
        lifespan={30}
        skipFirstVisit={true}
        displayPace={0} 
        customPromptElements={{
            container: 'athContainer'
        }}
        customPromptPlatformDependencies={{
            chromium: {
            images: [
                {
                src: 'images/guidance/chromium.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            },
            edge: {
            images: [
                {
                src: 'images/guidance/chromium.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            },
            iphone: {
            images: [
                {
                src: 'images/guidance/iphone.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            },
            ipad: {
            images: [
                {
                src: 'images/guidance/ipad.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            },
            firefox: {
            images: [
                {
                src: 'images/guidance/firefox.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            },
            samsung: {
            images: [
                {
                src: 'images/guidance/firefox.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            },
            opera: {
            images: [
                {
                src: 'images/guidance/opera.png',
                alt: 'Guide to install application on home screen.'
                }
            ]
            }
        }}
        />
        <App />
    </React.StrictMode>, 
    document.getElementById('root')
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
