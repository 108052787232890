import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'the-platform';


const CurrentRank = ({t})=> {
    const dispatch = useDispatch();
    const smallDevice = useMedia('(max-width: 959px)');
    let currentRank = useSelector(state => state.appReducer.currentRank);

    const setCurrentRankCheckFun = () => {
        dispatch({ type: 'SET_CURRENT_RANK', currentRank: !currentRank })
        if (!currentRank) {
            dispatch({ type: 'SET_CURRENT_POG', currentPog: false })
        }
    }

    const memorizedCurrentRank = useMemo(() =>
        <>
            <input type="checkbox" id="todo" name="todo" checked={currentRank} onChange={setCurrentRankCheckFun} />
            <label className="chk-label" htmlFor="todo" data-content={`${smallDevice ? 'Rank' : 'Rank by Stock'}`}>{`${smallDevice ? t('Rank') : t('Rank by Stock')}`}</label>
        </>, [currentRank, setCurrentRankCheckFun,t]);

    return <div className="vista-search-current-rank mr-3 w-100 background-theme-blue">
        {memorizedCurrentRank}
    </div>
}

export default  withTranslation()(CurrentRank);