
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { store } from '../redux/store';
import swal from 'sweetalert';
dayjs.extend(localizedFormat);

export default {
  formats: {
    dateTime: "MM/DD/YYYY hh:mm:ss A",
    date: "MM/DD/YYYY",
    dateIn: "DD/MM/YYYY",
    dateTimeIn: "DD/MM/YYYY hh:mm:ss A"
  },
  dateParse: function (value) {
    if (!value) {
      return null;
    }
    value = value.toString();

    if (value.indexOf('-') !== -1) {
      return new Date(value)
    }

    return new Date(Number(value.substr(0, 4)),
      Number(value.substr(4, 2)) - 1,
      Number(value.substr(6, 2)),
      Number(value.substr(8, 2)),
      Number(value.substr(10, 2)),
      Number(value.substr(12, 2))
    )
  },
  getLocalDateTime: function (utcDate) {
    if (!utcDate) {
      return;
    }
    let d = new Date(utcDate);
    let offset = new Date().getTimezoneOffset();
    d.setMinutes(d.getMinutes() - offset);
    return d;
  },
  DateLocalizer: function (value) {
    let date = value.value instanceof Date ? value.value : this.dateParse(value.value);
    let localDate = this.getLocalDateTime(date);
    date = localDate && localDate.getFullYear() > 1970 ? dayjs(localDate).format('lll') : '';
    return date;
  },
  DateTimeWithLocalTimeZone: function (value) {
    var date = value.value instanceof Date ? value.value : this.dateParse(value.value);
    var localDate = date ? this.getLocalDateTime(date) : '';
    date = localDate && localDate.getFullYear() > 1970 ? dayjs(localDate).format(this.formats.dateTime) : '';
    var timeZone = localDate ? localDate.toString().replace(/^.* (?:\((.*)\)|([A-Z]{1,4})(?:[\-+][0-9]{4})?(?: -?\d+)?)$/, "$1$2").replace(/[^A-Z]/g, "") : '';
    return date ? date + ' ' + timeZone : '';
  },
  DateTimeWithoutTimezone: function (value) {
    let reduxStore = store.getState().appReducer;
    let userData = reduxStore.userData ? reduxStore.userData.tags : {};
    let dateFormat = userData.CustomDateTimeFormat ? userData.CustomDateTimeFormat : this.formats.dateTime;
    let date = value.value instanceof Date ? value.value : this.dateParse(value.value);
    date = date ? dayjs(date).format(dateFormat) : '';
    return date;
  },
  dateMatch(value) {
    let reduxStore = store.getState().appReducer;
    let userData = reduxStore.userData ? reduxStore.userData.tags : {};
    let dataTimeFormat = userData.CustomDateTimeFormat ? userData.CustomDateTimeFormat : 'MM/DD/YYYY h:mma';
    const vistaDateTime = dayjs(value, "YYYYMMDDHHmmss000").format(dataTimeFormat);
    return vistaDateTime
  },
  calculateDateDiff(fromDate, toDate) {
    let date1 = new Date(fromDate),
      date2 = new Date(toDate);
    return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10) + 1;
  },
  isValidForm(fromDate, toDate, isMonthlyFilter = false) {
    let isValid = true;
    if ((Date.parse(fromDate) >= Date.parse(dayjs(toDate).add(1, 'days')))) {
      swal({ title: "Start date shouldn't be greater than End date", text: "", icon: "info", dangerMode: true });
      isValid = false
    }

    if (isMonthlyFilter && isValid) {
      if (this.calculateDateDiff(fromDate, toDate) > 30) {
        swal({ title: "Please select date range in 30 days", text: "", icon: "info", dangerMode: true });
        isValid = false
      }
    }
    return isValid;
  },
  clientAssignedDateFormat(value) {
    let reduxStore = store.getState().appReducer;
    let userData = reduxStore.userData ? reduxStore.userData.tags : {};
    const dateFormat = (userData.DateFormat && userData.DateFormat.toUpperCase()) || this.formats.date;
    return dateFormat;
  }
}