import React from 'react';
import { Grid, Card, Box, Divider } from '@material-ui/core';
import { CellMeasurer } from 'react-virtualized';
import { useSelector, useDispatch } from 'react-redux';
import { useMedia } from 'the-platform';
import VistaLocation from '../VistaLocationCardList/VistaLocation';
import CameraPhotoTakenInformation from './CameraPhotoTakenInformation';
import CoolerView2 from '../VistaLocationDetailsCardList/CoolerView2';
import OOSProductList from './OOSProductList';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import VistaImage from '../VistaLocationCardList/VistaImage';
import ValueDisplayCard from './ValueDisplayCard';
import ShowPlanogramInStore from '../Common/ShowPlanogramInStore';
import dateUtil from '../../utils/date';
import utils from '../../utils';
import clsx from 'clsx';

const VistaCameraCard = React.memo(function VistaCameraCard({ keyCard, index, style, parent, cache }) {
    const dispatch = useDispatch();
    const mediumDevice = useMedia({ minWidth: 401, maxWidth: 960 });
    //Vista Data From redux 
    const vistaData = useSelector(state => state.appReducer.vistaData);
    const data = (vistaData.records && vistaData.records[index]) || {}

    const imageURL = (data && data.StitchedImageURL) || ImageNotFound;
    let isLeftImageStitched = data.IsStitched === 1,
        cam1Image = '',
        cam2Image = '';
    if (!isLeftImageStitched) {
        cam1Image = `${imageURL.split("ImageType")[0]}ImageType=cam1`;
        cam2Image = `${imageURL.split("ImageType")[0]}ImageType=cam2`;
    }
    const isPlanogramAvailable = data && data.PlanogramId ? true : false;
    let { ProductInfo } = data;

    //Manage child AssetData, if available 
    const hasChild = data && data.Children && data.Children.length ? true : false;
    let child_Data, child_imageURL = ImageNotFound, child_AssetSerialNumber, child_purityDateTime;
    let avgPlanogramCompliance = 0, avgOos = 0;
    let { OOSPercentage = 0, PlanogramCompliance = 0 } = data;
    if (hasChild) {
        child_Data = data && data.Children && data.Children[0];
        if (child_Data) {
            child_imageURL = (child_Data.StitchedImageURL) || ImageNotFound;
            child_AssetSerialNumber = child_Data.AssetSerialNumber;
            child_purityDateTime = child_Data.PurityDateTime ? dateUtil.dateMatch(child_Data.PurityDateTime) : null;
            avgPlanogramCompliance = (child_Data.PlanogramCompliance + PlanogramCompliance) / 2; // Getting avg planogram comp & oos % for double door asset data.
            avgOos = (child_Data.OOSPercentage + OOSPercentage) / 2;
        }
    } else {
        avgPlanogramCompliance = PlanogramCompliance;
        avgOos = OOSPercentage;
    }

    let totalFacings = ProductInfo && ProductInfo.reduce(function (sum, rec) {
        return sum + rec.FacingInPlanogram;
    }, 0);

    let coolerProduct = ProductInfo && ProductInfo.filter(e => !(e.IsEmpty || e.IsForeign));
    let totalCoolerProduct = coolerProduct && coolerProduct.reduce(function (sum, rec) {
        return sum + rec.FacingInCooler;
    }, 0);

    let coolerProductPerc = (totalCoolerProduct / totalFacings) * 100;
    if (Object.keys(data).length <= 0) return false;

    return (
        <CellMeasurer
            key={keyCard}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={index}>
            {({ measure, registerChild }) => {
                return <div style={style} className="card-wrapper container2" ref={registerChild}>
                    <div className="card-row-wrapper">

                        <Grid container spacing={2} direction="row" alignItems="stretch">
                            <Grid item xs={12} sm={6} md={3} className="d-flex">
                                <Card className="mb-4" style={{ width: '100%' }}>

                                    <Box style={{ padding: '1.25rem' }} className="px-3 d-flex align-items-start justify-content-between">
                                        <h5 style={{ marginBottom: 0 }} className="card-header-text">{data.AssetSerialNumber}</h5>

                                    </Box>
                                    <div className="divider" />
                                    <VistaLocation index={index} data={data} />
                                    <div className="divider" />
                                    <CameraPhotoTakenInformation index={index} data={data} />
                                    {isLeftImageStitched ?
                                        <img onLoad={measure} style={{ objectFit: 'contain', height: mediumDevice ? '462px' : '200px', width: '100%', backgroundColor: '#000' }} alt='' id={`image-view-${index}`} src={imageURL} onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: imageURL } })} /> :
                                        <div class='d-flex w-100'>
                                            <img
                                                className={clsx(`image-container manual-stitch vcard-image `, {
                                                    height465: mediumDevice
                                                })}
                                                onLoad={measure}
                                                id={`image-view-${index}`}
                                                onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${cam1Image}&maxWidth=300` } })}
                                                src={`${cam1Image}&maxWidth=300`}
                                            />
                                            <img
                                                className={clsx(`image-container manual-stitch vcard-image `, {
                                                    height465: mediumDevice
                                                })}
                                                onLoad={measure}
                                                id={`image-view-${index}`}
                                                onClick={() => dispatch({ type: 'SET_VISTA_IMAGE_POPUP', vistaPopupData: { open: true, url: `${cam2Image}&maxWidth=300` } })}
                                                src={`${cam2Image}&maxWidth=300`} />
                                        </div>
                                    }
                                </Card>
                            </Grid>
                            {!isPlanogramAvailable ?
                                <Grid item xs={12} sm={12} md={9} className="d-flex ">
                                    <Card className="mb-4" style={{ width: '100%' }}>
                                        <VistaImage data={data} isFromVistaDetail={true} />
                                    </Card>
                                </Grid> : utils.isMobile() ? <></> :
                                    <>
                                        <Grid item xs={12} sm={6} md={5} className="d-flex ">
                                            <Card className="mb-4" style={{ width: '100%' }}>
                                                <ShowPlanogramInStore index={index} label={avgPlanogramCompliance} coolerProductPerc={coolerProductPerc} />
                                                <div className="divider" />
                                                <div className="d-flex customScrollbar" style={{ overflow: 'scroll' }}>
                                                    <CoolerView2 index={index} data={data} showSeparator={true} />
                                                    {hasChild && <>
                                                        <Divider orientation="vertical" flexItem />
                                                        <CoolerView2 index={index} data={child_Data} showSeparator={true} />
                                                    </>}
                                                </div>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} className="d-flex " direction="column">
                                            <ValueDisplayCard />
                                            <Card className="mb-4" style={{ width: '100%' }}>
                                                <OOSProductList index={index} data={data} />
                                            </Card>
                                        </Grid>
                                    </>
                            }
                        </Grid>
                    </div>
                </div>
            }}
        </CellMeasurer>
    );
})


export default VistaCameraCard