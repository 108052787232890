import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@mui/icons-material/Close';
import { withTranslation } from 'react-i18next';
import utils from '../../utils';
const t = utils.t;

const SearchByLocationFilter = React.memo(function SearchByLocationFilter(props) {
    const dispatch = useDispatch();
    const locationSearch = useSelector(state => state.appReducer.locationSearch) || '';
    const loaderOpen = useSelector(state => state.appReducer.loaderOpen);
    const { placeholder, size, t: transalte, i18n } = props;
    const tOpts = { t: transalte, i18n };
    const setSearchValueFun = useCallback((event) => {
        const { value = '' } = event.target;
        dispatch({ type: 'SET_LOCATION_SEARCH', locationSearch: value });
    }, [dispatch])

    const clearSearchValue = () => {
        dispatch({ type: 'SET_LOCATION_SEARCH', locationSearch: "" });
    }

    return (<div className="vista-search-input deleteicon">
        <TextField
            className="search-background search"
            variant="outlined"
            placeholder={placeholder || t("Search to Filter", tOpts)}
            value={locationSearch}
            onChange={setSearchValueFun}
            disabled={loaderOpen}
            fullWidth={true}
            InputProps={{
                'aria-label': 'Filter by Location',
                startAdornment: (<InputAdornment position="start"><CloseIcon className="icon-search" onClick={clearSearchValue} /></InputAdornment>),
                endAdornment: (<InputAdornment position="end"><SearchIcon className="icon-search" /></InputAdornment>)
            }}
            size={size}
        />
    </div>)
});

export default withTranslation()(SearchByLocationFilter);