import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { CellMeasurer } from 'react-virtualized';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import CoolerView2 from './CoolerView2';
import InventoryNeededList from './InventoryNeededList';
import ImageNotFound from '../../assets/images/vista-images/imagenotfound.jpg';
import VistaImage from '../VistaLocationCardList/VistaImage';
import shareIcon from '../../assets/images/vista-images/share.svg';
import copyIcon from '../../assets/images/vista-images/copy.png'
import locationPin from '../../assets/images/vista-images/locationpin.png';
import ShowPlanogramInStore from '../Common/ShowPlanogramInStore';
import VistaGoodBad from '../VistaLocationCardList/VistaGoodBad';
import VistaPhotoTake from '../VistaLocationCardList/VistaPhotoTake';
import VistaLocation from '../VistaLocationCardList/VistaLocation';
import VistaBatteryStatus from '../VistaLocationCardList/VistaBatteryStatus';
import VistaCardMobile from '../VistaLocationCardList/VistaCardMobile';
import VistaImages from './VistaImages';
import { Typography, Tooltip, Box, Card, Divider, Grid, Hidden, Select, FormControl, MenuItem, TextField, Button } from '@material-ui/core';
import SocialShare from "../SocialShare";
import NeedsAttention from '../VistaLocationCardList/NeedsAttention';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import CurrentRank from '../VistaLocationCardList/CurrentRank';
import CurrentPog from '../VistaLocationCardList/CurrentPog';
import utils from '../../utils';
import CopyModal from './CopyModal'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import { apis, request } from '../../httpUtil';
import actions from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import DialogModal from '../Common/DialogModel';
import dateUtil from '../../utils/date';
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { automationModel } from '../../constants'
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import PromotionList from './PromotionList';
import useMobile from '../../utils/useMobile';
const t = utils.t;

const { COMPASS } = automationModel;
let Attention = React.memo(() => <NeedsAttention />);
let Rank = React.memo(() => <CurrentRank />);
let PogRank = React.memo(() => <CurrentPog />);

const useStyles = makeStyles(theme => ({
    inputRoot: {
        height: '47px',
        width: '100%',
        backgroundColor: "#fff",
    },
    input: {
        padding: '5px 4px !important',
    }
}));

const MemoizedHidden = React.memo(({ sortValue, setSortValueFun,
    setStateValueFun, stateSelectedValue, setStateSelectedValue,
    managerSelectedValue, setManagerSelectedValue, setManagerValueFun, t }) => {
    const classes = useStyles();
    let comboData = useSelector(state => state.appReducer.comboData) || {};
    const userData = useSelector(state => state.appReducer.userData);
    const { Unilever, HideRankByButton } = userData && userData.modules ? userData.modules : {};
    const [innerSortState, setInnerSortState] = useState({ event: "", value: sortValue });
    const [innerState, setInnerState] = useState(stateSelectedValue);
    const [innerManager, setInnerManager] = useState(managerSelectedValue);

    let { IsSuperAdmin } = userData && userData.tags ? userData.tags : "";
    const isAdminOrSuperAdmin = utils.isAdminORSuperAdmin(IsSuperAdmin);
    let stateArray = [{ LookupId: '', DisplayValue: 'State' }]
    let managerArray = [{ LookupId: '', DisplayValue: 'Sales Manager' }]
    if (Object.keys(comboData).length && comboData["State"]) {
        stateArray = comboData["State"]
    }
    if (Object.keys(comboData).length && comboData["SalesPerson"]) {
        managerArray = comboData["SalesPerson"]
    }

    const stateFilterOnChange = (event, newValue) => {
        if (newValue) {
            setInnerState(newValue)
        } else {
            setInnerState({ LookupId: '', DisplayValue: '' })
        }
    };
    const managerFilterOnChange = (event, newValue) => {
        if (newValue) {
            setInnerManager(newValue)
        } else {
            setInnerManager({ LookupId: '', DisplayValue: '' })
        }
    };

    const apply = () => {
        if (innerSortState.event) {
            setSortValueFun(innerSortState.event);
        }
        setStateSelectedValue(innerState);
        setStateValueFun(innerState.LookupId);
        setManagerSelectedValue(innerManager)
        setManagerValueFun(innerManager.LookupId)
    }

    const clear = () => {
        setInnerState({ LookupId: '', DisplayValue: '' });
        setInnerState({ LookupId: '', DisplayValue: '' });
        setManagerSelectedValue({ LookupId: '', DisplayValue: '' })
        setManagerValueFun("")
        setInnerState({ LookupId: '', DisplayValue: '' })
        setStateValueFun("")
    }

    return (
        <Hidden smDown={false}>

            <Box
                display="flex"
                flexWrap="wrap"
                className="pt-1 pb-1 flex-column w-100"
            >
                <Box className="pt-1 pb-1 w-100">
                    <div className="vista-search-select w-100">
                        <FormControl variant="outlined" style={{ width: "100%" }} className="w-100">
                            <Select className="search-select w-100" value={innerSortState.value} onChange={(e) => setInnerSortState({ event: e, value: e.target.value })} autoWidth={true}>
                                <MenuItem key={"LocationASC"} value={"LocationName ASC"}> Sort by A to Z</MenuItem>
                                <MenuItem key={"LocationDESC"} value={"LocationName DESC"}> Sort by Z to A</MenuItem>
                                <MenuItem key={"ImageASC"} value={"PurityDateTime ASC"}> Image Taken ASC</MenuItem>
                                <MenuItem key={"ImageDESC"} value={"PurityDateTime DESC"}>Image Taken DESC</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Box>
                <Box className="pt-1 pb-1">
                    <div className="vista-search-select w-100">
                        <FormControl variant="outlined" style={{ minWidth: "150px" }} className="w-100">
                            <Autocomplete
                                id="combo-box-state"
                                value={innerState}
                                classes={classes}
                                options={stateArray}
                                getOptionLabel={(option) => option.DisplayValue}
                                onChange={stateFilterOnChange}
                                renderInput={(params) => <TextField {...params} placeholder="State" value={stateSelectedValue} variant="outlined" />}
                            />
                        </FormControl>
                    </div>
                </Box>
                <Box className="pt-1 pb-1 pr-2">
                    <div className="vista-search-select w-100">
                        <FormControl variant="outlined" style={{ minWidth: "250px" }} className="w-100">
                            <Autocomplete
                                id="combo-box-manager"
                                fullWidth={true}
                                value={innerManager}
                                classes={classes}
                                options={managerArray}
                                getOptionLabel={(option) => option.DisplayValue}
                                onChange={managerFilterOnChange}
                                renderInput={(params) => <TextField {...params} placeholder="Manager" value={managerSelectedValue} variant="outlined" />}
                            />
                        </FormControl>
                    </div>
                </Box>
                <Box className="pt-1 pb-1 w-100">
                    {!(Unilever.Module && !isAdminOrSuperAdmin) &&
                        <Attention />
                    }
                </Box>
                {!(HideRankByButton.Module && !isAdminOrSuperAdmin) && <>
                    < Box className="pt-1 pb-1">
                        <Rank />
                    </Box>

                    <Box className="pt-1 pb-1" >
                        <PogRank />
                    </Box>
                </>
                }
                <div className="d-flex w-100 justify-content-end mt-5">
                    <Button variant='contained' className="text-white m-1 background-off-red" onClick={clear}>{t("Clear Filters")}</Button>
                    <Button variant="contained" className=" text-white m-1 background-theme-blue" onClick={apply}>{t("Apply")}</Button>
                </div>
            </Box>

        </Hidden>
    )
});



const VistaDetailCard = React.memo(function VistaDetailCard({
    keyCard, index, style, parent, cache, hideChildInfo, manualData = null, isCardContentOnly = false, isOutletCard = false,
    hideHeaderFilter, hidePrevNext, showImages = true, showInventory = true, t: translate, i18n, onClose }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const isMobile = useMobile();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [showReplenish, setShowReplenish] = useState({ modal: false, data: {} });
    const [automationValues, setAutomationValues] = React.useState({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
    //Vista Data From redux 
    const loginData = useSelector(state => state.appReducer.userData) || {};
    const replenishData = useSelector(state => state.appReducer.replenishData) || [];
    const clientId = loginData && loginData.tags && loginData.tags.ClientId ? Number(loginData.tags.ClientId) : null
    const vistaData = useSelector(state => state.appReducer.vistaData, shallowEqual);
    const data = manualData ? manualData : (vistaData.records && vistaData.records[index]) || {}
    const imageURL = (data && data.StitchedImageURL) || ImageNotFound;
    const isPlanogramAvailable = data && data.PlanogramId ? true : false;
    const isPOSImage = data && data.SceneTypeId == 5;
    const { ReplenishDetail } = loginData && loginData.modules ? loginData.modules : {};
    const tOpts = { t: translate, i18n };

    const [imageData, setImageData] = React.useState(data);

    const onShare = useCallback((e) => {
        setAnchorEl(e.currentTarget);
    }, [])

    useEffect(() => {
        if (automationValues.checked) {
            setAutomationValues({ totalScoreAvg: 0, checked: false, fullCount: 0, lowCount: 0, emptyCount: 0, automationData: {} });
        }
    }, [data]);


    const memorizedShare = useMemo(() => <span className="green" style={{ cursor: 'pointer', flex: '.09' }} onClick={onShare}> <img src={shareIcon} alt={""} /></span>, [onShare])

    const closeShareLink = () => setAnchorEl(null);

    //Manage child AssetData, if available 
    const hasChild = data && data.Children && data.Children.length && !hideChildInfo ? true : false;
    let child_Data, child_imageURL = ImageNotFound, child_AssetSerialNumber, child_AssetId;
    let child_purityDateTime = "";
    let { AvgPlanogramCompliance, AvgStockPercentage, PurityDateTime, ProductInfo } = isOutletCard ? imageData : data;
    if (hasChild) {
        child_Data = data && data.Children && data.Children[0];
        if (child_Data) {
            child_imageURL = (child_Data.StitchedImageURL) || ImageNotFound;
            child_AssetSerialNumber = child_Data.AssetSerialNumber;
            child_purityDateTime = child_Data.PurityDateTime;
            child_AssetId = child_Data.AssetId
        }
    }
    let purityDateTimeLabel = PurityDateTime ? dateUtil.dateMatch(PurityDateTime) : "";
    let childPurityDateTimeLabel = child_purityDateTime ? dateUtil.dateMatch(child_purityDateTime) : "";

    if (Object.keys(data).length <= 0) {
        return hideHeaderFilter ?
            <div></div> : "No Data"
    }

    const LatestDateTimeLabel = ({ dateTime }) => {
        return (<Tooltip title={t("View Image", tOpts)} placement="right">
            <span className="ellipsis-text card-footer-flex-asset mr-1">{isMobile.mobile && !isMobile.tablet ? <small>{dateTime}</small> : dateTime}</span>
        </Tooltip>);
    }

    const handleOpen = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    let totalFacings = ProductInfo && ProductInfo.reduce(function (sum, rec) {
        return sum + rec.FacingInPlanogram;
    }, 0);

    let coolerProduct = ProductInfo && ProductInfo.filter(e => !(e.IsEmpty || e.IsForeign));
    let totalCoolerProduct = coolerProduct && coolerProduct.reduce(function (sum, rec) {
        return sum + rec.FacingInCooler;
    }, 0);

    let coolerProductPerc = (totalCoolerProduct / totalFacings) * 100;

    const { RowNumber, LocationName = '', AssetSerialNumber = '', Street = '', Street2 = '', Street3 = '', City = '', State = '', PostalCode = '', AssetId, Code = '' } = data;
    let assetAddress = (Street ? Street : '') +
        (Street2 ? ', ' + Street2 : '') +
        (Street3 ? ', ' + Street3 : '') +
        (City ? ', ' + City : '') +
        (State ? ', ' + State : '') +
        (PostalCode ? ', ' + PostalCode : '');
    let toolTipTitle = `${LocationName}, ${assetAddress}`;
    const onReplenishDetailClick = async (assetId, imageDate) => {
        if (ReplenishDetail.Module && !isPOSImage) {
            let params = { action: 'getReplenishOrder', otherAction: 'getReplenishOrder', AssetId: assetId }
            const response = await request({ url: apis.AssetPurityList, params, history, dispatch });
            if (response && response.success) {
                dispatch({ type: actions.SET_REPLENISH_ORDER, replenishData: response.data || [] });
            } else {
                dispatch({ type: actions.SET_REPLENISH_ORDER, replenishData: [] });
            }
            setShowReplenish({ modal: true, data: { imageDate: imageDate } });
        }
    }
    const handleSwitchChange = async (preAiData, event) => {
        const toggleValue = event.target.checked;
        if (!toggleValue || Object.keys(preAiData).length > 0) {
            setAutomationValues((prevState) => ({ ...prevState, checked: toggleValue }));
            return;
        }
        const params = { action: 'loadImage', clientId: clientId, currentId: Number(event.target.value), reProcess: true, modelName: COMPASS };
        const response = await request({ url: apis.ImageProcessing, params, history, dispatch });
        let aiData = {};
        if (response) {
            const parseResponse = JSON.parse(response);
            if (parseResponse && parseResponse.ImageProcessResult) {
                aiData = JSON.parse(parseResponse.ImageProcessResult).AutomationData;
                let totalScore = 0, lowCount = 0, emptyCount = 0, fullCount = 0, score = 0, totalScoreAvg = 0;
                aiData.scores.forEach(scores => {
                    score = scores ? Number((scores * 100).toFixed(0)) : 0;
                    totalScore += score;
                    if (score < 50) {
                        emptyCount++;
                    }
                    else if (score >= 50 && score < 75) {
                        lowCount++;
                    }
                    else if (score >= 75) {
                        fullCount++;
                    }
                });
                totalScoreAvg = totalScore > 0 ? totalScore / aiData.scores.length : 0;
                totalScoreAvg = Number(totalScoreAvg.toFixed(0));
                setAutomationValues({ totalScoreAvg: totalScoreAvg, checked: toggleValue, emptyCount: emptyCount, fullCount: fullCount, lowCount: lowCount, automationData: aiData });
            } else {
                setAutomationValues({ totalScoreAvg: 0, checked: toggleValue, emptyCount: 0, fullCount: 0, lowCount: 0, automationData: aiData });
                toast("No response from API, Please contact to admin");
            }
        } else {
            setAutomationValues({ checked: toggleValue, automationData: aiData });
            toast("Something went wrong. Please try again later");
        }
    };
    let createdOn = replenishData[0]?.CreatedOn;
    if (createdOn) {
        createdOn = dayjs(Number(createdOn.replace(/[/Date()/]/g, ""))).format(dateUtil.formats.dateTimeIn);
    }

    const cardContentOnly = () => {
        const hideCardHeader = (isMobile.mobile && !isMobile.tablet && isOutletCard) ? true : false;

        return <>
            <div style={{ ...style }} key={keyCard}
                className={`card-wrapper container2 ${!hideHeaderFilter ? "vista-container-custom" : ''} ${isMobile.mobile ? "over-flow-auto" : ""} ${isCardContentOnly ? "mb-0" : ""}`}>
                {(!hideHeaderFilter && isMobile.mobile) && <div className="card-row-mobile-wrapper">
                    <VistaCardMobile data={{ ...data }} />
                </div>}
                <div className="card-row-wrapper">
                    {isPlanogramAvailable || isPOSImage ?

                        <Grid container spacing={1}>
                            {showImages ?
                                <Grid item xs={12} sm={12} md={isMobile.mobile ? 6 : 4} className="d-flex">
                                    <Card className="mb-4 Vista-Detail-left-card-container" style={{ width: '100%' }}>
                                        {!hideCardHeader && <div className={clsx("d-flex w-100")}>
                                            <Tooltip title={t(toolTipTitle, tOpts)} placement="right">
                                                <h5 className="location_header_card_1 card-header-text ellipsis-text mb-0"> {LocationName}, {assetAddress} <br />
                                                    <h6>{Code}</h6></h5></Tooltip>
                                            <Tooltip title={t(`Show all at this address`, tOpts)} placement="right">
                                                <span className="red location_header_card_1_icon"
                                                    onClick={() => {
                                                        dispatch({ type: 'SET_VISTA_SEARCH', vistaSearch: LocationName });
                                                        onClose();
                                                    }} > <img src={locationPin} alt={`pin${RowNumber}`} />
                                                </span></Tooltip>
                                        </div>}
                                        <VistaImages data={isOutletCard ? imageData : data} isOutletCard={isOutletCard}
                                            setImageData={setImageData} index={index} hidePrevNext={hidePrevNext}
                                            leftImageDateTime={PurityDateTime} rightImageDateTime={child_purityDateTime} idx={index} />
                                        {hasChild ? <div>
                                            <div className="d-flex text-center" style={{ paddingTop: '.5rem' }}>
                                                <Typography
                                                    className={`ellipsis-text card-footer-flex-asset ${ReplenishDetail.Module && 'replenish-link'}`}
                                                    title={AssetSerialNumber}
                                                    variant="h4" gutterBottom
                                                    onClick={() => onReplenishDetailClick(AssetId, purityDateTimeLabel)}>{AssetSerialNumber}</Typography>
                                                <Typography className={`ellipsis-text card-footer-flex-asset ${ReplenishDetail.Module && 'replenish-link'}`}
                                                    title={child_AssetSerialNumber} variant="h4" gutterBottom
                                                    onClick={() => onReplenishDetailClick(child_AssetId, childPurityDateTimeLabel)}>{child_AssetSerialNumber}</Typography>
                                            </div>
                                            <div className="d-flex text-center" style={{ paddingTop: '.5rem' }}>
                                                <div className="card-footer-flex-date"> <LatestDateTimeLabel dateTime={purityDateTimeLabel} /></div>
                                                <div className="card-footer-flex-date"> <LatestDateTimeLabel dateTime={childPurityDateTimeLabel} /></div>
                                            </div>
                                            <div className="d-flex justify-content-end" style={{ padding: '.5rem 1rem' }}>
                                                <Tooltip title={t("View Image", tOpts)} placement="right">
                                                    <span className="green mr-1" style={{ cursor: 'pointer', flex: '.09' }}
                                                        onClick={() => dispatch({
                                                            type: 'SET_VISTA_IMAGE_POPUP',
                                                            vistaPopupData: {
                                                                open: true, isMultiImages: true, url: [{ src: imageURL, title: data.AssetSerialNumber },
                                                                { src: child_imageURL, title: child_AssetSerialNumber }]
                                                            }
                                                        })}> <PhotoLibrary color="primary" /></span>
                                                </Tooltip>
                                                <Tooltip title={t("share this information", tOpts)} placement="right">{memorizedShare}</Tooltip>
                                                <SocialShare data={data} anchorEl={anchorEl} closeShareLink={closeShareLink} />
                                                <Tooltip title={t("Open Card for Copy", tOpts)} placement="right" >
                                                    <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => handleOpen()}>
                                                        <img src={copyIcon} height="20" alt={"Copy"} />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </div> :
                                            <div >
                                                <div className={clsx("d-flex")} style={{ padding: '.5rem 1rem' }}>
                                                    <Typography className={`ellipsis-text card-footer-flex-asset pt-1 ${ReplenishDetail.Module && 'replenish-link'}`}
                                                        title={AssetSerialNumber} variant="h4" gutterBottom
                                                        onClick={() => onReplenishDetailClick(AssetId, purityDateTimeLabel)}>{AssetSerialNumber}</Typography>
                                                    <LatestDateTimeLabel dateTime={purityDateTimeLabel} />
                                                    <Tooltip title={t("share this information", tOpts)} placement="right">{memorizedShare}</Tooltip>
                                                    <SocialShare data={data} anchorEl={anchorEl} closeShareLink={closeShareLink} />
                                                    {!hideChildInfo && <Tooltip title={t("Open Card for Copy", tOpts)} placement="right" >
                                                        <span style={{ cursor: 'pointer', flex: '.09' }} onClick={() => handleOpen()}>
                                                            <img src={copyIcon} height="20" alt={"Copy"} />
                                                        </span>
                                                    </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Card>
                                </Grid> : null}
                            {isMobile.mobile ? "" : <Grid item xs={12} sm={12} md={isOutletCard ? 8 : 4} className="d-flex">
                                <Card className={clsx("mb-4", { "hide-component": isMobile.mobile })} style={{ width: '100%' }}>
                                    {!isPOSImage && <ShowPlanogramInStore index={index} label={AvgPlanogramCompliance}
                                        coolerProductPerc={coolerProductPerc} isOutletCard={isOutletCard} />}
                                    <div className="divider" />
                                    <div className="d-flex customScrollbar" style={{ overflow: 'scroll' }} key={Math.random()}>
                                        <CoolerView2 index={index} data={isOutletCard ? imageData : data} showSeparator={true} />
                                        {!isPOSImage && hasChild && <>
                                            <Divider orientation="vertical" flexItem />
                                            <CoolerView2 index={index} data={child_Data} showSeparator={true} />
                                        </>}
                                    </div>
                                </Card>
                            </Grid>}
                            {!isOutletCard && <Grid item xs={12} sm={12} md={isMobile.mobile ? 6 : 4} className="d-flex ipad-media-for-inventory">
                                {isPOSImage ?
                                    <PromotionList index={index} data={data} />
                                    :
                                    showInventory ?
                                        <InventoryNeededList index={index} data={data} avgStock={AvgStockPercentage} /> : null}
                            </Grid>}
                        </Grid>
                        : !isMobile.mobile ?
                            <div className="card-row-wrapper">
                                <Card className="card-box mb-4 vistaCard" >
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={6} md={3} style={{ marginBottom: '1.25rem' }} className="virtual-list-left">
                                            <Box className="card-box2">
                                                <h4 className="font-size-lg  my-2 name">{data.AssetSerialNumber}</h4>
                                            </Box>
                                            <div className="divider my-4" />
                                            <VistaLocation data={data} onClose={onClose} />
                                            <div className="divider my-4" />
                                            {(utils.batteryPermissionClientIds.includes(clientId)) && <>
                                                <VistaBatteryStatus data={data} />
                                                <div className="divider my-4" />
                                            </>}
                                            <VistaPhotoTake data={{ ...data }} />
                                            <div className="divider my-4" />
                                            {(utils.batteryPermissionClientIds.includes(clientId)) && <>
                                                <Box display="flex">
                                                    <Box flexGrow={0.3} m={1}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={automationValues.checked}
                                                                    onChange={handleSwitchChange.bind(this, automationValues.automationData)}
                                                                    name="checked"
                                                                    value={data.AssetPurityId}
                                                                />}
                                                            label={t("Analytics", tOpts)}
                                                            labelPlacement='bottom'
                                                        />
                                                    </Box>
                                                    <Box flexGrow={0.3} alignItems="center" display="flex">
                                                        {/* replace the values in {} with dynamic value from API */}
                                                        <Box component="h2">
                                                            {automationValues.totalScoreAvg}%
                                                        </Box>
                                                    </Box>
                                                    <Box flexGrow={0.4} display="flex" justifyContent="flex-end" flexDirection="column" m={1}>
                                                        <p className="p-0 m-0"><span>
                                                            <FontAwesomeIcon icon={faCircle} size="lg" className="text-success pr-2" />
                                                        </span>{automationValues.fullCount} Full</p>
                                                        <p className="p-0 m-0"><span>
                                                            <FontAwesomeIcon icon={faCircle} size="lg" className="text-warning pr-2" />
                                                        </span>{automationValues.lowCount} Low</p>
                                                        <p className="p-0 m-0"><span>
                                                            <FontAwesomeIcon icon={faCircle} size="lg" className="text-danger pr-2" />
                                                        </span>{automationValues.emptyCount} Empty</p>
                                                    </Box>
                                                </Box>
                                                <div className="divider my-4" />
                                            </>}
                                            <VistaGoodBad data={{ ...data }} />
                                        </Grid>
                                        <Grid item xs={12} md={9} className="virtual-list-right">
                                            <VistaImage data={data} isToggleChecked={automationValues.checked} aiData={automationValues.automationData} />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div> : ""
                    }

                    {showModal && <CopyModal
                        idx={keyCard}
                        data={{
                            toolTipTitle: toolTipTitle,
                            LocationName: LocationName,
                            hasChild: hasChild,
                            imageURL: imageURL,
                            child_imageURL: child_imageURL,
                            AssetSerialNumber: AssetSerialNumber,
                            child_AssetSerialNumber: child_AssetSerialNumber,
                            purityDateTimeLabel: purityDateTimeLabel,
                            childPurityDateTimeLabel: childPurityDateTimeLabel
                        }}
                        showModal={showModal}
                        handleClose={handleClose} />}
                    {(showReplenish.modal && ReplenishDetail.Module) &&
                        <DialogModal open={showReplenish.modal} onClose={() => setShowReplenish({ modal: false, data: {} })}
                            dialogTitle={`${t("Replenish Detail", tOpts)} ${"- " + createdOn || ""} `}
                            maxWidth="md" className="replenish-table" hideCancel={true}>
                            <Grid container spacing={1}>
                                <Grid md={12} sm={12} lg={12} >
                                    <table>
                                        <tr>
                                            <th>{t('Product Name', tOpts)}</th>
                                            <th>{t('Stock Level (In %)', tOpts)}</th>
                                            <th>{t('Tiers', tOpts)}</th>
                                            <th>{t('Units', tOpts)}</th>
                                            <th>{t('Units Needed', tOpts)}</th>
                                            <th>{t('Units Type', tOpts)}</th>
                                            <th>{t('Units Price', tOpts)}</th>
                                            <th>{t('Total Price', tOpts)}</th>
                                        </tr>
                                        {replenishData.map(e => {
                                            return (
                                                <tr>
                                                    <td>{e.ProductName}</td>
                                                    <td>{e.StockLevel}</td>
                                                    <td>{e.PlanogramTiers}</td>
                                                    <td>{e.PlanogramUnits}</td>
                                                    <td>{e.UnitsNeeded}</td>
                                                    <td>{t('Case of', tOpts)} {e.CaseSize}</td>
                                                    <td>{e.UnitPrice}</td>
                                                    <td>{e.Amount}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                    {replenishData.length == 0 && <div className="text-center pt-3">{t("No Data", tOpts)}</div>}
                                </Grid>
                            </Grid>
                        </DialogModal>
                    }
                </div>
            </div>
        </>
    }


    return (<>
        {isCardContentOnly ?
            <>{cardContentOnly()}</> :
            <CellMeasurer key={keyCard} cache={cache} parent={parent} columnIndex={0} rowIndex={index} >
                {cardContentOnly()}
            </CellMeasurer>}
    </>
    );
})

export default withTranslation()(VistaDetailCard);