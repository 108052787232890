
import React from "react";
import ButtonWithIcon from "./menuComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { activationAction, ORDER_STATUS } from "../../constants";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector, } from 'react-redux';
import { withRouter } from "react-router";
import utils from "../../utils";

const t = utils.t;

function Operations({ t: trans, i18n, history }) {
    const { checkedData, isReorder, isIgnore, isSchedule, data, isTransit, getData, enableActivationAction } = useSelector(state => state.appReducer.operationsCheckedData) || {};
    const { rowData } = useSelector(state => state.appReducer.activateLocationReorder) || {};
    const dispatch = useDispatch();
    const tOpts = { t: trans, i18n }

    const onScheduledIgnoreClick = (e, orderStatus) => {
        utils.onScheduledIgnoreClick({ e, isMulti: true, data, statusId: orderStatus, getData, isReorder, dispatch, history, checkedData, t })
    }
    const onActivateDeactivateLocation = (event, statusId) => {
        utils.onActivateDeactivateLocation({ event, statusId, getData, dispatch, history, locationIds: checkedData, t })
    }
    const menuItems = [{
        label: t('IGNORE', tOpts),
        onClick: (e) => onScheduledIgnoreClick(e, ORDER_STATUS.IGNORED),
        disabled: false,
        color: 'background-theme-blue  text-white',
        show: !isTransit && isSchedule
    },
    {
        label: t('IN-TRANSIT', tOpts),
        onClick: (e) => onScheduledIgnoreClick(e, ORDER_STATUS.INTRANSIT),
        disabled: false,
        color: 'background-theme-blue  text-white',
        show: !isTransit && isSchedule && !isIgnore
    },
    {
        label: t('SCHEDULE', tOpts),
        onClick: (e) => onScheduledIgnoreClick(e, ORDER_STATUS.SCHEDULED),
        disabled: false,
        color: 'background-theme-blue  text-white',
        show: !isTransit && isIgnore,

    },
    !rowData?.IsActive && {
        label: t('ACTIVATE', tOpts),
        onClick: (e) => onActivateDeactivateLocation(e, activationAction.ACTIVE),
        disabled: false,
        show: enableActivationAction,
        color: 'background-theme-blue  text-white',
    },
    rowData?.IsActive && {
        label: t('DE-ACTIVATE', tOpts),
        onClick: (e) => onActivateDeactivateLocation(e, activationAction.DEACTIVE),
        disabled: false,
        show: enableActivationAction,
        color: 'background-off-red  text-white'
    }]

    return <>
        <ButtonWithIcon size="small" btnClass={`background-theme-blue sub-header-button`} showIconOnMobile={true} showLabelOnWeb={true} icon={<FontAwesomeIcon icon={faListAlt} />} label={t("Operations", tOpts)} menuItems={menuItems} disabled={!checkedData.length} iconButtonClass="iconButtonClass" />
    </>
}

export default withTranslation()(withRouter(Operations));