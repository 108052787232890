import React from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import CustomizedLabel from '../Common/CustomizedLabel';
import { Box, Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import utils from '../../utils';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
const t = utils.t;

const InventoryNeededList = React.memo(function InventoryNeededList({ data: { ProductInfo = [], Children = null }, avgStock = 0, t: translate, i18n }) {
    const tOpts = { t: translate, i18n }
    let records = [], ChildProductInfo;
    const hasChild = Children && Children.length ? true : false;
    const userData = useSelector(state => state.appReducer.userData);
    const { Unilever } = userData && userData.modules ? userData.modules : {};
    let { IsSuperAdmin } = userData && userData.tags ? userData.tags : "";
    const isAdminOrSuperAdmin = utils.isAdminORSuperAdmin(IsSuperAdmin);

    if (hasChild) {
        ChildProductInfo = (Children && Children[0].ProductInfo);
        if (ChildProductInfo.length) {
            var uniqueArray = [...ProductInfo.map((e) => e.ProductId), ...ChildProductInfo.map((e) => e.ProductId)].filter(function (item, i, ar) { return ar.indexOf(item) === i; });
            uniqueArray.map((i) => {
                let parentProduct = ProductInfo.filter(e => e.ProductId === i);
                let childProduct = ChildProductInfo.filter(e => e.ProductId === i);
                let record = { FacingInCooler: 0, FacingInPlanogram: 0, PercentageOfProduct: 0 };
                if (parentProduct.length > 0) {
                    record = Object.assign({}, parentProduct[0]);
                } else {
                    record = Object.assign({}, childProduct[0]);
                }
                if (parentProduct.length > 0 && childProduct.length > 0) {
                    record.FacingInCooler += childProduct[0].FacingInCooler;
                    record.FacingInPlanogram += childProduct[0].FacingInPlanogram;
                    record.PercentageOfProduct += childProduct[0].PercentageOfProduct;
                }
                records.push(record);
            })
        }
    } else {
        records = ProductInfo;
    }
    let emptyProducts = records.filter((a) => a.IsEmpty);
    let emptyCount = emptyProducts.reduce(function (sum, rec) {
        return sum + rec.FacingInCooler;
    }, 0);

    let nonEmptyProducts = records.filter((a) => !a.IsEmpty);
    records = emptyProducts.concat(nonEmptyProducts);


    let totalFacings = records.reduce(function (sum, rec) {
        return sum + rec.FacingInPlanogram;
    }, 0);

    let totalForeignFacings = records.reduce(function (sum, rec) {
        if (rec.FacingInPlanogram === 0) {
            return sum + rec.FacingInCooler;
        } else {
            return sum;
        }
    }, 0);

    let totalFacingsInCooler = records.reduce(function (sum, rec) {
        if (rec.IsEmpty) {
            return sum;
        } else {
            return sum + rec.FacingInCooler;
        }
    }, 0);

    if (records.length > 0) {
        records.push({
            FacingInCooler: totalFacingsInCooler,
            FacingInPlanogram: totalFacings,
            ProductName: t("Total", tOpts)
        })
    }

    let nonCoolerProduct = records.filter(e => e.IsEmpty || e.IsForeign);
    let totalNonCoolerFacing = nonCoolerProduct.reduce(function (sum, rec) {
        return sum + rec.FacingInCooler;
    }, 0);

    const missingProduct = ({ cellData, rowData }) => {
        let isEmpty = rowData.ProductName.toLowerCase().indexOf("empty") > -1;
        let isTotal = rowData.ProductName.toLowerCase().indexOf("total") > -1;
        let value = rowData.FacingInCooler; //#23133
        let style = value < rowData.FacingInPlanogram || isEmpty ? { color: '#FF0000' } : { color: '#008000' }
        return <div className={isEmpty || isTotal ? "font_weight_600" : ""} style={style}>{Math.abs(value)}</div>
    }
    const productNameRenderer = ({ value, rowData }) => {
        let isEmpty = rowData.ProductName.toLowerCase().indexOf("empty") > -1;
        let isTotal = rowData.ProductName.toLowerCase().indexOf("total") > -1;
        if (rowData.IsForeign) {
            rowData.ProductName = 'Unknown'
        }
        return <div className={isEmpty || isTotal ? "font_weight_600" : ""} title={rowData.ProductName}>{rowData.ProductName}</div>
    }
    return (<Card className="mb-4" style={{ width: '100%' }}>
        <Box style={{ padding: '1.25rem' }} className="px-3 d-flex align-items-center justify-content-between">
            <h5 className="card-header-text mb-0">{t("Inventory", tOpts)}</h5>
            <div className={clsx("d-flex justify-content-between", { "hide-component": utils.isMobile() })}>
                <div className="pl-1 pr-1">
                    <CustomizedLabel label={`${emptyCount} ${t("empty", tOpts)}`} />
                </div>
                {(Unilever.Module && !isAdminOrSuperAdmin) ?
                    <CustomizedLabel label={`${totalNonCoolerFacing}/${totalFacings} OOS`} /> :
                    <CustomizedLabel label={`${avgStock}% ${t("in stock", tOpts)}`} />}
            </div>
        </Box>
        <div className="divider" />

        <div style={{ maxHeight: '85%', minHeight: '85%', marginTop: 0 }}>
            <AutoSizer style={utils.isMobile() ? { width: "100%", height: "100%" } : {}}>
                {({ height, width }) => (
                    <Table
                        width={width}
                        height={height - 2}
                        headerHeight={38}
                        rowHeight={height / 10}
                        rowCount={records.length}

                        rowGetter={({ index }) => records[index]}>
                        <Column
                            className="vista-location-product-table-data pl-1"
                            label={t('Product Name', tOpts)}
                            headerStyle={{ textAlign: 'left', color: '#3B3E66' }}
                            dataKey='ProductName'
                            headerClassName="column-header-style"
                            cellRenderer={productNameRenderer}
                            width={width * 0.65} />

                        <Column
                            className="vista-location-product-table-data text-align-center"
                            label={t('Facings', tOpts)}
                            headerStyle={{ textAlign: 'center', color: '#3B3E66' }}
                            dataKey='FacingInPlanogram'
                            headerClassName="column-header-style"
                            width={width * 0.25}
                            cellRenderer={({ cellData, rowData }) => {
                                let isEmpty = rowData.ProductName.toLowerCase().indexOf("empty") > -1;
                                let isTotal = rowData.ProductName.toLowerCase().indexOf("total") > -1;
                                return <div className={isEmpty || isTotal ? "font_weight_600" : ""}>{cellData}</div>
                            }}
                        />
                        <Column
                            className="vista-location-product-table-data text-align-center"
                            label={t('In Store', tOpts)}
                            dataKey='FacingInCooler'
                            headerClassName="column-header-style"
                            width={width * 0.2}
                            headerStyle={{ textAlign: 'center', color: '#3B3E66' }}
                            justifyContent={'center'}
                            cellRenderer={missingProduct}
                        />
                    </Table>
                )}
            </AutoSizer>
        </div> </Card>)
})

export default withTranslation()(InventoryNeededList);