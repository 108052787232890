import React from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import actions from '../redux/actions';
let pendingRequests = 0;
const transport = axios.create({ withCredentials: true });

const getFormData = (props) => {
    let formData = new FormData();
    for (let key in props) {
        if (typeof props[key] == "object") {
            formData.append(key, JSON.stringify(props[key]));
        } else {
            formData.append(key, props[key]);
        }
    }
    return formData;
}

const exportRequest = (url, query) => {
    const newURL = new URL(url);
    for (let key in query) {
        const value = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
        newURL.searchParams.append(key, value);
    }
    window.open(newURL, '_blank').focus();
}

const request = async ({ url, params = {}, history, dispatch, jsonPayload = false, additionalParams = {}, additionalHeaders = {}, disableLoader = false }) => {

    if (params.exportData) {
        return exportRequest(url, params);
    }
    if (!disableLoader) {
        dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
    }
    pendingRequests++;
    let reqParams = {
        method: 'POST',
        credentials: 'include',
        url: url,
        headers: jsonPayload ? { ...additionalHeaders } : { 'Content-Type': 'multipart/form-data', ...additionalHeaders },
        ...additionalParams
    };
    if (params) {
        reqParams.data = jsonPayload ? params : getFormData(params);
    }

    try {
        let response = await transport(reqParams);
        pendingRequests--;
        let data = response.data;
        if (response) {
            if (pendingRequests === 0) {
                dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
            }
            if (response.status === 200) {
                let json = response.data;
                if (json.success === false) {
                    if (json.info === 'Session has expired!') {
                        dispatch({ type: actions.SET_USER_DATA, userData: {} });
                        swal({
                            title: "Your session has expired. Please login again.",
                            text: "",
                            icon: "error",
                            dangerMode: true
                        });
                        history.push('/login');
                        return;
                    }
                    else if (response.status === 200) {
                        return data;
                    }
                }
                else {
                    return data;
                }
            }
        } else {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
            return data;
        }
    } catch (ex) {
        pendingRequests--;
        if (pendingRequests === 0) {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false })
        }
        if (ex?.response?.status === 401) {
            dispatch({ type: actions.SET_USER_DATA, userData: {} });
            swal({
                title: ex.response.data.info,
                text: "",
                icon: "error",
                dangerMode: true
            });
            history.push('/login');
        } else if (ex?.response?.status === 500) {
            swal({
                title: ex?.response?.data?.info,
                text: "",
                icon: "error",
                dangerMode: true
            });
        }
        else {
            console.error(ex);
            return { error: "" };
        }
    }

    return <></>
}
export default request;