import React from 'react';
import { Card } from '@material-ui/core';

const CustomizedLabel = props => {
    let { label } = props
    return <Card className="customized-label" style={{ backgroundColor: "#3d4977" }}>
        <div className="text-white customized-label-text">{label}</div>
    </Card>
}

export default CustomizedLabel;