import { Inbox } from "@material-ui/icons"


export const LOGIN_CONST = {
    USERNAME: 'Username',
    PASSWORD: 'Password',
    CLIENT: 'Client'
}
export const STRINGS_CONST = {
    OOS_REPORT_TITLE: "Out Of Stock Report",
    INVENTORY_TITLE: "In Stock % Summary Report",
    EXCEL_FORMAT: 'XLSX',
    SCHEDULED_EXPORT_FILE_NAME: 'Scheduled_order',
    SCHEDULED_ORDER_TITLE: 'Scheduled Reorder List',
    REORDER_EXPORT_FILE_NAME: 'Reorder',
    REORDER_TITLE: 'Reorder List',
    IGNORE_EXPORT_FILE_NAME: 'Ignore_order',
    IGNORE_ORDER_TITLE: 'Ignore Reorder List',
    INTRANSIT_EXPORT_FILE_NAME: 'Intransit_Location',
    INTRANSIT_ORDER_TITLE: 'Intransit Location List',
    ASSET_COUNT_WITH_ISSUE: 'Asset Counts With Issues',
    HEALTH_ISSUE: 'Health Issues',
    PRESALE_REPORT: 'Presales Report',
    SCHEDULED_ORDER_EMAIL_TITLE: 'Scheduled Reorder Email List',
    SCHEDULED_ORDER_EMAIL_EXPORT_FILE_NAME: 'Scheduled_Order_Email',
    SURVEY_TITLE: "Survey Report",
    SCHEDULED_STATUS_HISTORY_TITLE: "Schedule Status History Report",
    LOCATION_ORDER_HISTORY_TITLE: "Location Order History",
    REPLENISHMENT_ORDER_STATUS_INBOX: "Replenishment Order Status Inbox",
    REPLENISHMENT_ORDER_STATUS_SCHEDULED: "Replenishment Order Status Scheduled",
    REPLENISHMENT_ORDER_STATUS_INTRANSIT: "Replenishment Order Status In-Transit",
    REPLENISHMENT_ORDER_STATUS_IGNORE: "Replenishment Order Status Ignore",
    REPLENISHMENT_ORDER_STATUS_OVERVIEW: "Replenishment Order Status Overview"
}

export const ORDER_STATUS = {
    SCHEDULED: 5485,
    IGNORED: 5486,
    INTRANSIT: 5484,
    INBOX: 0

}

export const SCHEDULE_INTRANSIT_EXPORT_COLUMNS = [
    { title: 'Datling Id', dataIndex: 'DatlingId', type: 'string' },
    { title: 'Order Id', dataIndex: 'OrderId', type: 'string' },
    { title: 'Order Date', dataIndex: 'OrderDate', type: 'datetime' },
    { title: 'Name Customer', dataIndex: 'NameCustomer', type: 'string' },
    { title: 'Address Customer', dataIndex: 'AddressCustomer', type: 'string' },
    { title: 'House Number', dataIndex: 'HouseNumber', type: 'string' },
    { title: 'Postal Code', dataIndex: 'PostalCode', type: 'string' },
    { title: 'City', dataIndex: 'City', type: 'string' },
    { title: 'Telephone', dataIndex: 'LocationPhone', type: 'string' },
    { title: 'Article Description', dataIndex: 'ArticleDescription', type: 'string' },
    { title: 'EAN Code', dataIndex: 'EANCode', type: 'string' },
    { title: 'Quantity', dataIndex: 'Quantity', type: 'decimal' },
    { title: 'Entered By', dataIndex: 'EnteredBy', type: 'string' },
    { title: 'First Name', dataIndex: 'FirstName', type: 'string' },
    { title: 'Last Name', dataIndex: 'LastName', type: 'string' },
    { title: 'Mobile', dataIndex: 'Mobile', type: 'string' },
    { title: 'Telephone Work', dataIndex: 'TelephoneWork', type: 'string' },
    { title: 'Email Address', dataIndex: 'EmailAddress', type: 'string' },
    { title: 'Comments For Wholesaler', dataIndex: 'CommentsForWholesaler', type: 'string' }
]

export const REORDER_IGNORE_EXPORT_COLUMNS = [
    { title: 'Location Code', dataIndex: 'Code', type: 'string' },
    { title: 'Name Customer', dataIndex: 'LocationName', type: 'string' },
    { title: 'Address Customer', dataIndex: 'FullAddress', type: 'string' },
    { title: 'House Number', dataIndex: 'HouseNumber', type: 'string' },
    { title: 'Postal Code', dataIndex: 'PostalCode', type: 'int' },
    { title: 'City', dataIndex: 'City', type: 'string' },
    { title: 'Telephone', dataIndex: 'Telephone', type: 'string' },
    { title: 'Out of Stock(%)', dataIndex: 'OutOfStock', type: 'decimal' },
    { title: 'Re-Stock in (Days)', dataIndex: 'DaysLeftInVisit', type: 'string' },
    { title: 'Quantity (Cases)', dataIndex: 'TotalCase', type: 'int' },
    { title: 'Order', dataIndex: 'TotalOrder', type: 'decimal' },
    { title: 'Active', dataIndex: 'IsActive', type: 'bool' }
]

export const SITE_SUB_DOMAIN_NAME = {
    UNILEVER: 'goldeneye-unilever'
}

export const MENU_IDS = {
    LOCATION_REORDER: 7202,
    IN_TRANSIT: 7201
}

export const MOVEMENT_STATUS = {
    MANUAL: 5537
}

export const ACOSTA_REPORT_COLUMNS = [
    { title: 'AlertId', dataIndex: 'AlertId', type: 'string', width: 150 },
    { title: 'Acosta AlertId', dataIndex: 'AcostaAlertId', type: 'int', width: 220 },
    { title: 'Alert Text', dataIndex: 'AlertText', type: 'string', width: 500, className: 'white-space-pre', tooltip: true },
    { title: 'SerialNumber #', dataIndex: 'SerialNumber', type: 'string', width: 200 },
    { title: 'Alert Type Id', dataIndex: 'AcostaAlertTypeId', type: 'string', width: 200 },
    { title: 'UPC Code', dataIndex: 'SkuUpcCode', type: 'string', width: 200 },
    { title: 'Product', dataIndex: 'Product', type: 'string', width: 400 },
    { title: 'Closed/Open', dataIndex: 'AlertSatus', type: 'string', width: 220 },
    { title: 'Acosta Comments', dataIndex: 'Comments', type: 'string', width: 300 },
    { title: 'Acosta Visited', dataIndex: 'Answer', type: 'string', width: 400 },
    { title: 'Acosta Status', dataIndex: 'Status', type: 'string', width: 150 },
    { title: 'Question', dataIndex: 'Question', type: 'string', width: 500, className: 'white-space-initial', tooltip: true },
    { title: 'Answer', dataIndex: 'Answer', type: 'string', width: 500, className: 'white-space-initial', tooltip: true },
    { title: 'Validate Reason', dataIndex: 'ValidateReason', type: 'string', width: 200 },
    { title: 'Market', dataIndex: 'MarketName', type: 'string', width: 200 },
    { title: 'Location', dataIndex: 'Location', type: 'string', width: 300 },
    { title: 'Street', dataIndex: 'Street', type: 'string', width: 300 },
    { title: 'Country', dataIndex: 'Country', type: 'string', width: 200 },
    { title: 'Retailer Relationship Code', dataIndex: 'RetailerRelationshipCode', type: 'string', width: 300 },
    { title: 'CallId', dataIndex: 'CallId', type: 'string', width: 220 },
    { title: 'Store', dataIndex: 'Store', type: 'string', width: 350 },
    { title: 'File Name', dataIndex: 'ImageFileName', type: 'string', width: 250 },
    { title: 'Acosta Number', dataIndex: 'AcostaNumber', type: 'string', width: 200 },
    { title: 'StoreId', dataIndex: 'StoreId', type: 'string', width: 150 },
    { title: 'Client', dataIndex: 'Client', type: 'string', width: 280 },
    { title: 'Sub Category', dataIndex: 'SubCategory', type: 'string', width: 150 },
    { title: 'SubBanner', dataIndex: 'SubBanner', type: 'string', width: 250 },
    { title: 'Priority', dataIndex: 'Priority', type: 'string', width: 150 },
    { title: 'Alert Age', dataIndex: 'AlertAge', type: 'string', width: 300 },
    { title: 'Status Date', dataIndex: 'StatusDate', type: 'datetime', width: 250 },
    { title: 'Date Planned', dataIndex: 'DatePlanned', type: 'datetime', width: 250 },
    { title: 'Completed On Local', dataIndex: 'CompletedOnLocal', type: 'datetime', width: 250 },
    { title: 'Start Date', dataIndex: 'StartDate', type: 'datetime', width: 250 },
    { title: 'End Date', dataIndex: 'EndDate', type: 'datetime', width: 250 },
    { title: 'Last Edited', dataIndex: 'LastEdited', type: 'datetime', width: 250 },
    { title: 'Alert Age (in min)', dataIndex: 'AlertAgeInMin', type: 'string', width: 250 },
    { title: 'Alert SentOn', dataIndex: 'AlertSentOn', type: 'datetime', width: 250 }
]

export const ORDER_LOOKUP = {
    5485: 'Scheduled Order',
    5486: 'Ignored Order',
    5484: 'In-Trasit Order',
    0: 'Reorder'
}
export const activationAction = {
    ACTIVE: 1,
    DEACTIVE: 2,
    BOTH: 0,
    NONE: 3
}
export const THUMB_MAX_WIDTH = 105;

export const automationModel = {
    COMPASS: 'Compass_new'
}

export const SCHEDULED_ORDER_EMAIL_LOG_COLUMNS = [
    { title: 'Product Name', dataIndex: 'ProductName', type: 'string' },
    { title: 'EAN Code', dataIndex: 'EANCode', type: 'string' },
    { title: 'Outlet Code', dataIndex: 'LocationCode', type: 'string' },
    { title: 'Location', dataIndex: 'LocationName', type: 'string' },
    { title: 'Re-Stock in (Days)', dataIndex: 'RestockIn', type: 'string' },
    { title: 'Cases', dataIndex: 'Quantity', type: 'int' },
    { title: 'Order', dataIndex: 'Amount', type: 'decimal' },
    { title: 'Order Date', dataIndex: 'OrderDate', type: 'datetime' },
    { title: 'Delivery Date', dataIndex: 'DeliveryDate', type: 'datetime' },
    { title: 'Order Number', dataIndex: 'OrderNumber', type: 'decimal' },
    { title: 'Customer Name', dataIndex: 'ClientName', type: 'string' },
    { title: 'Sent On', dataIndex: 'SentOnUTC', type: 'datetime' }
]

export const SURVEY_REPORT_COLUMNS = [
    { title: 'Survey Id', dataIndex: 'SurveyId', type: 'string', width: 50 },
    { title: 'Survey Type', dataIndex: 'SurveyType', type: 'string', width: 250 },
    { title: 'Survey Category', dataIndex: 'SurveyCategory', type: 'string', width: 250 },
    { title: 'Location Name', dataIndex: 'OutletName', type: 'string', width: 250 },
    { title: 'Location Code', dataIndex: 'OutletCode', type: 'string', width: 100 },
    { title: 'Section Id', dataIndex: 'SectionId', type: 'string', width: 50 },
    { title: 'Section Title', dataIndex: 'SectionTitle', type: 'string', width: 250 },
    { title: 'Question Id', dataIndex: 'QuestionId', type: 'string', width: 50 },
    { title: 'Question Text', dataIndex: 'QuestionText', type: 'string', width: 250 },
    { title: 'Answer Key', dataIndex: 'AnswerKey', type: 'string', width: 50 },
    { title: 'Answer', dataIndex: 'Answer', type: 'string', width: 250 },
    { title: 'Attachment', dataIndex: 'Attachment', type: 'string', width: 250 },
    { title: 'Survey Date Time', dataIndex: 'SurveyDateTime', type: 'string', width: 250 },
    { title: 'Modified By User', dataIndex: 'ModifiedByUser', type: 'string', width: 250 },
]

export const SCHEDULED_STATUS_HISTORY_REPORT_COLUMNS = [
    { title: 'Location Name', dataIndex: 'LocationName', type: 'string', width: 150 },
    { title: 'Order Status', dataIndex: 'OrderStatus', type: 'string', width: 250 },
    { title: 'Out Of Stock(%)', dataIndex: 'OutOfStock', type: 'string', width: 250 },
    { title: 'Total Order($)', dataIndex: 'TotalOrder', type: 'string', width: 250 },
    { title: 'Total Case', dataIndex: 'TotalCase', type: 'string', width: 250 },
    { title: 'Modified On', dataIndex: 'ModifiedOn', type: 'string', width: 300 },
    { title: 'User', dataIndex: 'ModifiedByUser', type: 'string', width: 300 },
]


export const LOCATION_ORDER_HISTORY = [
    { title: 'Location', dataIndex: 'LocationName', type: 'string', width: 150 },
    { title: 'Out Of Stock(%)', dataIndex: 'OutOfStock', type: 'string', width: 250 },
    { title: 'Restock In', dataIndex: 'RestockIn', type: 'string', width: 250 },
    { title: 'Cases', dataIndex: 'TotalCase', type: 'string', width: 250 },
    { title: 'Order', dataIndex: 'TotalOrder', type: 'string', width: 250 },
    { title: 'Order Date', dataIndex: 'OrderDate', type: 'string', width: 300 },
    { title: 'Delivery Date', dataIndex: 'DeliveryDate', type: 'string', width: 300 },
    { title: 'Created On', dataIndex: 'TimeStamp', type: 'string', width: 300 },
    { title: 'Order Status', dataIndex: 'OrderStatusId', type: 'string', width: 300 },
    { title: 'Delivery Date', dataIndex: 'DeliveryDate', type: 'string', width: 300 },
]

export const LABELMAPPINGS = {
    "WTD": "This Week",
    "MTD": "Month to Date",
    "QTD": "Quarter to Date ",
    "YTD": "Year to Date",
    "ClientName": "Clients",
    "SurveyType": "Survey Type",
    "SurveyCategory": "Survey Category",
    "SurveyDateTime": "Survey Date Time",
    "ModifiedByUser": "User",
    "MarketName": "Market Name"
}
export const LABELPOSITION = [
    "WTD",
    "MTD",
    "QTD",
    "YTD"
]

export const PERIODDATEMAPPINGS = {
    "WTD": "startOfWeek",
    "MTD": "startOfMonth",
    "QTD": "startOfQuarter",
    "YTD": "startOfYear"
}