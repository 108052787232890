const actions = {
  LOGIN: 'LOGIN',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_SIDEBAR_TOGGLE: 'SET_SIDEBAR_TOGGLE',
  SET_HEADER_FIXED: 'SET_HEADER_FIXED',
  SET_HEADER_SHADOW: 'SET_HEADER_SHADOW',
  SET_SIDEBAR_TOGGLE_MOBILE: 'SET_SIDEBAR_TOGGLE_MOBILE',
  SET_SIDEBAR_HOVER: 'SET_SIDEBAR_HOVER',
  SET_SIDEBAR_FIXED: 'SET_SIDEBAR_FIXED',
  CAMERA_IMAGE_LIST: 'CAMERA_IMAGE_LIST',
  ADD_REMOTE_COMMAND: 'ADD_REMOTE_COMMAND',
  SET_TIMESLOT_DIALOG_STATE: 'SET_TIMESLOT_DIALOG_STATE',
  SET_VISTA_DATA: 'SET_VISTA_DATA',
  SET_VISTA_DATA_RECORD: 'SET_VISTA_DATA_RECORD',
  SET_SEARCH_FILTER_VALUE: 'SET_SEARCH_FILTER_VALUE',
  SET_SORTING_FILTER_VALUE: 'SET_SORTING_FILTER_VALUE',
  SET_NEEDS_ATTENTION_FILTER_VALUE: 'SET_NEEDS_ATTENTION_FILTER_VALUE',
  SET_VISTA_LIST_COUNT_DATA: 'SET_VISTA_LIST_COUNT_DATA',
  UPDATE_LOADER_STATE: 'UPDATE_LOADER_STATE',
  UPDATE_SCHEDULE_TIME_POPUP: 'UPDATE_SCHEDULE_TIME_POPUP',
  RESET_VISTA_DATA: 'RESET_VISTA_DATA',
  SET_STATE_MAGNIFIER: 'SET_STATE_MAGNIFIER',
  SET_VISTA_SEARCH: 'SET_VISTA_SEARCH',
  SET_VISTA_IMAGE_POPUP: 'SET_VISTA_IMAGE_POPUP',
  SET_NEED_ATTENTION: 'SET_NEED_ATTENTION',
  RESET_STATE: 'RESET_STATE',
  SET_LOCATIONS_DATA: 'SET_LOCATIONS_DATA',
  RESET_LOCATIONS_DATA: 'RESET_LOCATIONS_DATA',
  SET_LOCATION_SEARCH: 'SET_LOCATION_SEARCH',
  SET_PREV_NEXT_VISTA_DATA_RECORD: 'SET_PREV_NEXT_VISTA_DATA_RECORD',
  SET_USER_LOGOUT: 'SET_USER_LOGOUT',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_CLIENT_SELECT: 'SET_CLIENT_SELECT',
  SET_CLIENT_LIST_DATA: 'SET_CLIENT_LIST_DATA',
  SET_USER_PASSWORD: 'SET_USER_PASSWORD',
  SET_PLANOGRAM_CHECKBOX: 'SET_PLANOGRAM_CHECKBOX',
  SET_INSTORE_CHECKBOX: 'SET_INSTORE_CHECKBOX',
  SET_OPTIONAL_CHECKBOX: 'SET_OPTIONAL_CHECKBOX',
  SET_OVERVIEW_CHART_DATA: 'SET_OVERVIEW_CHART_DATA',
  SET_PRE_SALES_DATA: 'SET_PRE_SALES_DATA',
  SET_PRE_SALES_DETAIL_DATA: 'SET_PRE_SALES_DETAIL_DATA',
  SET_COMBO_DATA: 'SET_COMBO_DATA',
  SET_FROM_DATE_FILTER: 'SET_FROM_DATE_FILTER',
  SET_TO_DATE_FILTER: 'SET_TO_DATE_FILTER',
  SHOW_VISTA_DETAIL_POP_UP: 'SHOW_VISTA_DETAIL_POP_UP',
  SET_OOS_REPORT_CHART_DATA: 'SET_OOS_REPORT_CHART_DATA',
  SET_SKU_OOS_LIST_DATA: 'SET_SKU_OOS_LIST_DATA',
  SET_FILTER_VALUES: 'SET_FILTER_VALUES',
  SET_CURRENT_RANK: 'SET_CURRENT_RANK',
  SET_CURRENT_POG: 'SET_CURRENT_POG',
  RESET_SKU_OOS_LIST_DATA: 'RESET_SKU_OOS_LIST_DATA',
  SET_OOS_SKU_DETAIL_LIST: 'SET_OOS_SKU_DETAIL_LIST',
  SET_OOS_COLLAPSED_LIST: 'SET_OOS_COLLAPSED_LIST',
  RESET_OOS_SKU_DETAIL_LIST: 'RESET_OOS_SKU_DETAIL_LIST',
  SET_HEADER_FILTERS: 'SET_HEADER_FILTERS',
  SET_IMAGE_PREVIEW: 'SET_IMAGE_PREVIEW',
  SET_HEADER_TITLE: 'SET_HEADER_TITLE',
  SET_INVENTORY_DATA: 'SET_INVENTORY_DATA',
  SET_DATE_FILTERS: 'SET_DATE_FILTERS',
  SET_PRODUCT_SEARCH: 'SET_PRODUCT_SEARCH',
  SET_TELEMETRIC_SEARCH: 'SET_TELEMETRIC_SEARCH',
  SET_LOCATIONS_REORDER_DATA: 'SET_LOCATIONS_REORDER_DATA',
  SET_SCHEDULED_REORDER_DATA: 'SET_SCHEDULED_REORDER_DATA',
  SET_IGNORED_REORDER_DATA: 'SET_IGNORED_REORDER_DATA',
  SET_IN_TRANSIT_DATA: 'SET_IN_TRANSIT_DATA',
  SET_SITE_NAME: 'SET_SITE_NAME',
  SET_SCHEDULED_LOG: 'SET_SCHEDULED_LOG',
  SET_ACOSTA_REPORT_DATA: 'SET_ACOSTA_REPORT_DATA',
  SET_REPLENISH_ORDER: 'SET_REPLENISH_ORDER',
  SET_REORDER_LOG: 'SET_REORDER_LOG',
  SET_KPI_FILTERS: 'SET_KPI_FILTERS',
  SET_KPI_DATA: 'SET_KPI_DATA',
  SET_KPI_GRID_DATA: 'SET_KPI_GRID_DATA',
  SET_KPI_IMAGE_DATA: 'SET_KPI_IMAGE_DATA',
  SET_CURRENT_LOCALE: 'SET_CURRENT_LOCALE',
  SET_FILTER_PANEL_TOGGLE: 'SET_FILTER_PANEL_TOGGLE',
  SET_PAGE_TITLE_DETAILS: 'SET_PAGE_TITLE_DETAILS',
  PASS_FILTERS_TOHEADER: 'PASS_FILTERS_TOHEADER',
  SET_OPERATIONS_INFORMATION: 'SET_OPERATIONS_INFORMATION',
  SET_LOCATION_FILTER: 'SET_LOCATION_FILTER',
  SET_KPI_PAGE_TITLE: 'SET_KPI_PAGE_TITLE',
  SET_ANSWER_DATA: 'SET_ANSWER_DATA',
  SET_IS_EXPANDED: 'SET_IS_EXPANDED',
  SET_APP_INFO: 'SET_APP_INFO',
  SET_SURVEY_TYPE: 'SET_SURVEY_TYPE',
  SET_TYPE_QUESTION: 'SET_TYPE_QUESTION',
  SET_ALL_SURVEY_DATA: 'SET_ALL_SURVEY_DATA',
  SET_RESET_ORDER_HISTORY_CLEAR: 'SET_RESET_ORDER_HISTORY_CLEAR',
  SET_ALL_ORDER_HISTORY_APPLIED_FILTERS: 'SET_ALL_ORDER_HISTORY_APPLIED_FILTERS',
  SET_ACTIVATE_LOCATION_REORDER: 'SET_ACTIVATE_LOCATION_REORDER',
  EDIT_REPLENISHMENT_ORDER: 'EDIT_REPLENISHMENT_ORDER',
  REPLENISHMENT_GRID_DATA: 'REPLENISHMENT_GRID_DATA',
  SET_PAGE_BACK_BUTTON: 'SET_PAGE_BACK_BUTTON',
  OVERVIEW_RECORD_SELECT: "OVERVIEW_RECORD_SELECT",
  PRODUCT_CASE_TYPE_DDL: "PRODUCT_CASE_TYPE_DDL",
  SAVE_GROUP_DETAILS: "SAVE_GROUP_DETAILS",
  SAVE_SURVEY_DETAILS: "SAVE_SURVEY_DETAILS"
};

export const setSidebarToggle = sidebarToggle => ({
  type: actions.SET_SIDEBAR_TOGGLE,
  sidebarToggle
});


export default actions;