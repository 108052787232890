import React, { memo, useRef, useLayoutEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import utils from '../../utils';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import apis from '../../httpUtil/apis'
import { THUMB_MAX_WIDTH } from '../../constants';
import { withTranslation } from 'react-i18next';
const t = utils.t;
const isSafari = navigator.vendor.includes('Apple');
function objContains(arr, obj) {
    let i = arr.length;
    while (i--) {
        if (JSON.stringify(arr[i]) === JSON.stringify(obj)) return true;
    }
    return false;
}

const EleRender = memo(({ p, indx, isPlanogram, isInStore, style, facingRecord, fHeight, thumbnail, fullName, pHeight, i, sku, showEmpty }) => {
    const [imageWidth, setImageWidth] = useState(0),
        facingEmpty = facingRecord[i].isEmpty,
        planEmpty = p.isEmpty,
        pImg = useRef(),
        fImg = useRef(),
        emptyFn = () => { };

    const setWidth = (ele) => {
        if (ele?.current && ele.current.width !== 0 && imageWidth !== ele.current.width) {
            if (isSafari) {
                setTimeout((e) => { setImageWidth(e.width) }, 50, ele.current)
            } else {
                setImageWidth(ele.current.width);
            }
        }
    }

    const setPWidth = () => {
        setWidth(fImg);
    }

    const setFWidth = () => {
        setWidth(pImg);
    }

    useLayoutEffect(() => {
        if (facingEmpty || planEmpty) {
            setWidth(facingEmpty && pImg || planEmpty && fImg);
        }
    })

    let pStyle = { height: pHeight, },
        fStyle = { height: fHeight };
    if (imageWidth !== 0) {
        pStyle.width = imageWidth;
        fStyle.width = imageWidth;
    }
    if (showEmpty) {
        pStyle = { height: fHeight, border: "2px solid #000" };
        pStyle.width = "25px";
    }

    let isEmptyStyle = facingRecord[i] && facingRecord[i].fullName && facingRecord[i].fullName.toLowerCase().startsWith("empty");
    let isBlankStyle = facingRecord[i] && facingRecord[i].id == 0;
    if (p.stackSize > 0) {
        return <div key={indx} className={clsx({ 'cooler-item-2-single-data': !(isPlanogram && isInStore), 'cooler-item-2': (isPlanogram && isInStore) })} style={style}><div className="cooler_data_container">
            {isInStore && indx === p.stackSize - 1 && <div className={clsx({ '': !(isPlanogram && isInStore), 'cooler_data_container__item': (isPlanogram && isInStore), "empty-pln-store-prd": isEmptyStyle })} >
                <img ref={fImg} src={facingRecord && facingRecord[i].thumbnail} alt="" title={facingRecord[i] && (`${facingRecord[i].fullName} ${facingRecord[i].sku || "N/A"}`)} style={fStyle} onLoad={planEmpty ? setPWidth : emptyFn}
                />
            </div>}
            {isPlanogram && <div className="cooler_data_container__item">
                <img ref={pImg} src={thumbnail} alt="" title={`${fullName} ${sku || "N/A"}`} style={pStyle} onLoad={facingEmpty ? setFWidth : emptyFn} />
            </div>}
        </div>
        </div>
    }
    else {
        return <div className="cooler-item-list">
            <div key={i} className={clsx({ 'cooler-item-2-single-data': !(isPlanogram && isInStore), 'cooler-item-2': (isPlanogram && isInStore) })} style={style}><div className="cooler_data_container">
                {isInStore && <div className={clsx({ '': !(isPlanogram && isInStore), 'cooler_data_container__item': (isPlanogram && isInStore), "empty-pln-store-prd": isEmptyStyle, "blank-pln-store-prd": isBlankStyle })} >
                    {!isBlankStyle ? <img ref={fImg} src={facingRecord && facingRecord[i].thumbnail} alt="" title={facingRecord[i] && (`${facingRecord[i].fullName} ${facingRecord[i].sku || "N/A"}`)} style={fStyle} onLoad={planEmpty ? setPWidth : emptyFn}
                    /> : <div style={fStyle} title="Blank" ></div>}
                </div>}
                {isPlanogram && (
                    <div className="cooler_data_container__item">
                        {showEmpty ? <div style={pStyle} title="Blank" ></div> :
                            <img ref={pImg} src={thumbnail} alt="" title={`${fullName} ${sku || "N/A"}`} style={pStyle} onLoad={facingEmpty ? setFWidth : emptyFn} />}
                    </div>)}
            </div>
            </div>
        </div>
    }
});

const PromotionEleRender = memo(({ p, indx, style, PromotionThumbnail, PromotionName, pHeight }) => {
    const [imageWidth, setImageWidth] = useState(0),
        promotionImg = useRef(),
        emptyFn = () => { };
    let pStyle = { height: pHeight };
    if (imageWidth !== 0) {
        pStyle.width = imageWidth;
    }
    return <div className="cooler-item-list">
        <div key={indx} style={style} className="cooler-item-2-single-data"><div className="cooler_data_container">
            <div className="cooler_data_container__item">
                <img ref={promotionImg} src={PromotionThumbnail} alt="" title={PromotionName} style={pStyle} onLoad={emptyFn} />
            </div>
        </div>
        </div>
    </div>
});
const PromotionColumnRender = React.memo(function PromotionColumnRender({ promotionRecord = "", index }) {

    return promotionRecord.map((p, i) => {
        let { PromotionId, PromotionThumbnail, PromotionName } = p;
        if (!PromotionId) {
            PromotionThumbnail = './images/blank.png';
        } else {
            PromotionThumbnail = `${apis.imgBaseUrl}/controllers/thumbnail.ashx?imageType=Promotion&ImagePath=${PromotionId}.png&maxWidth=${THUMB_MAX_WIDTH}`;
        }
        if (PromotionId === 0) {
            return <></>;
        }
        let pHeight = 80 * 0.7;
        const style = {};
        let options = { p, i, style, PromotionThumbnail, PromotionName, pHeight }
        return <PromotionEleRender {...options} key={i} />;
    })

});





const ColumnRenderer = React.memo(function ColumnRenderer({ planogramRecord = '', facingRecord = '', index }) {
    let isPlanogram = useSelector(state => state.appReducer.isPlanogram[index]);
    let isInStore = useSelector(state => state.appReducer.isInStore[index]);
    let diff = facingRecord.length - planogramRecord.length;
    let showEmpty = false;
    if (diff > 0) {
        while (diff > 0) {
            planogramRecord.push({
                categoryColor: null, color: null, compliant: true, depth: 0, fontColor: null, fontStyle: null, fullName: null, graphColor: null, height: 0, hideMissing: false,
                id: 0, isEmpty: true, isForeign: false, missing: true, name: null, productCategory: null, productCategoryId: 0, sizeRatio: 0, sku: null, stackSize: 0, textColor: null,
                thumbnail: `${apis.imgBaseUrl}/images/blank.png?16010101000000`, upc: null, width: 0
            });
            diff--;
        }
    }

    return planogramRecord.map((p, i) => {
        let { id, thumbnail, height, fullName, sku } = p;
        if (!id) {
            thumbnail = './images/blank.png';
        } else {
            thumbnail = `${apis.imgBaseUrl}/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=${id}.png&maxWidth=${THUMB_MAX_WIDTH}`;
        }
        if (!facingRecord[i].id) {
            facingRecord[i].thumbnail = './images/blank.png';
        } else {
            facingRecord[i].thumbnail = `${apis.imgBaseUrl}/controllers/thumbnail.ashx?imageType=Thumbnail&ImagePath=${facingRecord[i].id}.png&maxWidth=${THUMB_MAX_WIDTH}`;
        }
        const facingProductId = facingRecord[i] && facingRecord[i].id || 0;
        // if both planogram and facings don't have product then will will not showing col
        if (id === 0 && facingProductId === 0) {
            return <></>;
        }
        //  facing have productId > 0 and planogram have Id == 0 ie.e product in facing but not in planogram 
        if (facingProductId > 0 && id == 0) {
            thumbnail = './images/blank.png';
            showEmpty = true;
        }
        const bgRGBColor = facingRecord && facingRecord[i].color && utils.hexCodeToRGBA(facingRecord[i].color);
        let borderColor = facingRecord && facingRecord[i] && facingRecord[i].color;
        if (borderColor && !borderColor.includes("#")) borderColor = '#' + borderColor;
        let pHeight = (height || 80) * 0.7;
        let fHeight = ((facingRecord[i] && facingRecord[i].height) || 80) * 0.7;
        const style = (facingRecord && facingRecord[i].missing && { backgroundColor: bgRGBColor, borderColor: borderColor }) || {};
        isPlanogram = isPlanogram === undefined ? true : isPlanogram;
        isInStore = isInStore === undefined ? true : isInStore;

        if (p.stackSize > 0) {
            return <div className="cooler-item-list">
                {[...Array(p.stackSize)].map((x, indx) => {
                    let options = { p, indx, isPlanogram, isInStore, style, facingRecord, fHeight, thumbnail, fullName, pHeight, i, showEmpty }
                    return <EleRender {...options} key={indx} />
                })
                }
            </div>
        } else {
            let options = { p, i, isPlanogram, isInStore, style, facingRecord, fHeight, thumbnail, fullName, pHeight, sku, showEmpty }
            return <EleRender {...options} key={i} />;
        }
    })
})

function updateMissingProductDetail(planogramDetail, facing) {
    const productLegendArray = [];

    if (!planogramDetail.shelves.length > 0) return facing || { shelves: {} };
    const { isCategory, shelves } = planogramDetail;

    for (const [i, shelve] of shelves.entries()) {
        const { products } = shelve || {};
        for (const [j, product] of products.entries()) {
            const { id, productCategoryId, fullName, color, productCategory, categoryColor, isEmpty } = product;
            const value = { Name: fullName, Color: color, Category: productCategory, CategoryColor: categoryColor };
            if (!objContains(productLegendArray, value)) productLegendArray.push(value);

            let facingProduct = facing.shelves[i].products[j];
            if (!facingProduct) {
                facingProduct = { ...product, ...{ missing: true, hideMissing: true, color } };
            }
            else if (facingProduct && ((!isCategory && facingProduct.id !== id) || (isCategory && facingProduct.productCategoryId !== productCategoryId))) {
                facingProduct.missing = true;
                if (color) facingProduct.color = color;
            }
            else if (facingProduct && facingProduct.isEmpty && !isEmpty) {
                facingProduct = { ...facingProduct, ...{ missing: true, color } }
            }
            facing.shelves[i].products[j] = facingProduct;
        }
    }
    return facing;
}

const PromotionShelvesRender = React.memo(function promotionShelvesRenderer(props) {
    const { Promotion, index, showSeparator, isChestCooler } = props;

    if (Promotion?.length > 0) {
        return Promotion.map((p, i) => {
            const promotionRecord = p && p.Promotions;
            return <div class={`${isChestCooler ? 'd-flex' : ''}`} key={i}>
                <div className={`box-wrapper d-flex ${isChestCooler ? 'flex-column' : ''}`} style={{ width: 'fit-content' }}>
                    <PromotionColumnRender promotionRecord={promotionRecord} index={index} />
                </div>
                {showSeparator &&
                    <hr className={`hr-line ${isChestCooler ? 'vr-line' : ''}`} width="100%" />
                }
            </div>
        })
    }
})

const ShelvesRenderer = React.memo(function shelvesRenderer(props) {
    const { Planogram, Facings, index, showSeparator, isChestCooler, isPOSImage } = props;
    const planogramDetail = Planogram || { shelves: {} };
    const facingDetail = updateMissingProductDetail(planogramDetail, Facings);
    const planogram = Planogram.shelves, facing = facingDetail.shelves;

    return planogram.map((p, i) => {
        const planogramRecord = p && p.products;
        const facingRecord = facing && facing[i] && facing[i].products;
        return <div class={`${isChestCooler ? 'd-flex' : ''}`} key={i}>
            <div className={`box-wrapper d-flex ${isChestCooler ? 'flex-column' : ''}`} style={{ width: 'fit-content' }}>
                <ColumnRenderer planogramRecord={planogramRecord} facingRecord={facingRecord} index={index} />
            </div>
            {showSeparator &&
                <hr className={`hr-line ${isChestCooler ? 'vr-line' : ''}`} width="100%" />
            }
        </div>
    })
})

const CoolerView2 = React.memo(function CoolerView2({ data: { Planogram = '', Facings = '', IsChestCooler = false, SceneTypeId, Promotion = "" }, index, showSeparator = false, t: translate, i18n }) {
    const tOpts = { t: translate, i18n }
    let isPlanogram = useSelector(state => state.appReducer.isPlanogram[index]);
    let isInStore = useSelector(state => state.appReducer.isInStore[index]);
    isPlanogram = isPlanogram === undefined ? true : isPlanogram;
    isInStore = isInStore === undefined ? true : isInStore;
    const isPOSImage = SceneTypeId == 5;
    return <Grid container wrap="nowrap" style={{ width: 'auto' }}>
        <Grid item xs>
            <div style={{ maxHeight: isSafari ? null : '392px', height: '392px', display: 'flex', flexDirection: 'column' }}>
                <div className="p-3 d-flex" style={{ maxHeight: isSafari || IsChestCooler ? null : '392px', height: IsChestCooler ? null : '392px', flexDirection: `${IsChestCooler ? 'row' : 'column'}`, width: 'fit-content', minWidth: '100%' }}>
                    {
                        isPOSImage ?
                            <PromotionShelvesRender Promotion={Promotion} index={index} isChestCooler={IsChestCooler} showSeparator={showSeparator} />
                            :
                            !isPlanogram && !isInStore ? <div style={{ textAlign: "center", width: '100%' }}> {t("Please check one of the box above", tOpts)} </div> : Planogram || Facings ?
                                <ShelvesRenderer Planogram={Planogram} Facings={Facings} index={index} showSeparator={showSeparator} isChestCooler={IsChestCooler} /> :
                                <div style={{ textAlign: "center", width: '100%' }}> {t("No data", tOpts)}  </div>
                    }
                </div>
            </div>
        </Grid>
    </Grid>
})

export default withTranslation()(CoolerView2);
