import React from 'react';
import { HashRouter } from 'react-router-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import Routes from './routes';
import './assets/css/base.scss';
import './App.scss';
import "survey-core/defaultV2.min.css";
import "./utils/i18n"
import CssBaseline from '@material-ui/core/CssBaseline';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faSignal, faAngleRight, faWrench } from '@fortawesome/free-solid-svg-icons';
import { Toaster } from 'react-hot-toast';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ScrollUpButton from "react-scroll-up-button";
import { LicenseInfo } from '@mui/x-license-pro';
import utils from './utils';

library.add(faAngleDown);
library.add(faSignal);
library.add(faAngleRight);
library.add(faWrench);

LicenseInfo.setLicenseKey(utils.MUI_License);
function App() {
  return <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Toaster
        position="bottom-right"
        toastOptions={
          {
            style: {
              background: '#e8850a',
              color: '#fff'
            },
            duration: 5000
          }
        }
      />
      <ScrollUpButton />
      <HashRouter basename="/">
        <CssBaseline />
        <Routes />
      </HashRouter>
    </MuiPickersUtilsProvider>
  </Provider>;
}

export default App;
